.container {
  background: linear-gradient(to bottom right, #000a45, #9501f7);
  height: 22rem;
  display: flex;
  position: relative;
  color: #ffff;
}
.top_part {
  height: 10rem;
  position: relative;
  overflow: hidden;
}

.rectangle {
  position: absolute;
  right: 0;
  width: 9rem;
}

.rectangle18 {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 11rem;
}

.rectangle13 {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 6.8rem;
}

.rectangle19 {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 15rem;
  left: 15rem;
}

.rectangle20 {
  position: absolute;
  width: 5rem;
  bottom: 3rem;
  right: 29rem;
}

.rectangle16 {
  position: absolute;
  width: 5rem;
  bottom: 0rem;
  right: 0rem;
}

.top_img {
  width: 17rem;
  position: absolute;
  right: 4.3rem;
  top: -5rem;
}
.flex1_content {
  position: relative;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 60%;
  text-align: initial;
}

.flex1_content_heading {
  font-family: "poppinsBold";
  font-size: 2rem;
  margin: 0.5rem 0;
}

.flex1_content_description {
  margin-top: 1.5rem;
}

.flex1 {
  width: 60%;
}
.flex2 {
  width: 40%;
  position: relative;
}

.flex2_content {
  border: 1px solid #e5eaff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  border-radius: 4rem;
  width: 60%;
}

.download_title {
  border: 1px solid #ffff;
  padding: 0.2rem;
  border-radius: 0.7rem;
  margin: 0.5rem 0.5rem 2rem 0.5rem;
  width: 50%;
  margin: auto auto 2rem auto;

}

.store_button_container {
  margin: 0.5rem;
  /* width: 50%; */
  margin: 1rem auto;
}

.app_store {
  display: flex;justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.store_icon {
  width: 9.7rem;
  /* height: 2.9rem; */
  border-radius: 0.6rem;
}

.app_icon {
  width: 11rem;
  /* height: 4.2rem; */
  border-radius: 0.6rem;
  margin-left: -0.1rem;max-width: inherit;
}