.container {
  display: flex;
  margin-top: 5rem;
}

.flex1 {
  font-size: 2rem;
  font-family: "poppinsBold";
  width: 50%;
  text-align: initial;
  margin-left: 7rem;
}

.poppinsText {
  font-family: "poppinsMedium";
}

.app_view {
  width: 70%;
}

.download_tile {
  border: 1px solid #e5eaff;
  border-radius: 1rem;
  width: 70%;
  margin: 2rem auto;
  color: #3e4365;
  background-color: #ffff;
}

.app_store {
  /* width: 50%; */
  display: flex;
  background-color: #ffff;
  /* padding: 0.6rem 1rem; */
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.play_store {
  /* width: 50%; */
  display: flex;
  background-color: #ffff;
  /* padding: 0.6rem 1rem; */
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.store_icon {
  width: 8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  margin-top: 0.5rem;
} 

.app_icon {
  width: 9.6rem;
  margin-right: 0.5rem;
  object-fit: contain;
} 

.button_container {
  display: flex;
  padding: 1rem;
  justify-content: space-around;
}

.download_tile_title {
  margin: 2rem 0;
  font-weight: bold;
}

.img1 {
  display: block;
  width: 15rem;
  height: 22rem;
  position: absolute;
  top: 5.5rem;
}

.img2 {
  display: block;
  position: absolute;
  width: 15rem;
  top: 22rem;
}

.rectagle3 {
  display: block;
  position: absolute;
  width: 15rem;
  top: 22rem;
  width: 5.5rem;
  height: 5.5rem;
  z-index: 1;
}

.stats_container {
  position: relative;
  margin-top: 2rem;
}

.rectagle1 {
  width: 5.5rem;
  height: 5.5rem;
}

.stat1 {
  text-align: left;
  position: absolute;
  left: 8rem;
  top: 0.5rem;
  font-size: 1.5rem;
}

.stat2 {
  position: absolute;
  text-align: left;
  left: 17rem;
  top: 12.5rem;
  font-size: 1.5rem;
}

.stat3 {
  position: absolute;
  text-align: left;
  left: 7rem;
  top: 21.5rem;
  font-size: 1.5rem;
}
.stat4 {
  position: absolute;
  text-align: left;
  left: 16rem;
  top: 31rem;
  font-size: 1.5rem;
}

.number1 {
  color: #00afaa;
  font-size: 2.5rem;
}

.number2 {
  color: #425cc7;
  font-size: 2.5rem;
}

.stat_name {
  color: #797d93;
  font-size: 1rem;
}

.book_open {
  position: absolute;
  top: 1.4rem;
  left: 1.2rem;
  width: 3rem;
}

.rectagle2 {
  position: absolute;
  width: 5.6rem;
  height: 5.6rem;
  left: 9.4rem;
  top: 12.7rem;
  z-index: 2;
}

.book {
  position: absolute;
  left: 10.5rem;
  top: 14rem;
  width: 3rem;
  z-index: 3;
}

.user_check {
  position: absolute;
  left: 1.5rem;
  top: 23rem;
  width: 3rem;
  z-index: 3;
}

.video {
  position: absolute;
  left: 11.3rem;
  top: 32.5rem;
  width: 2.5rem;
  z-index: 3;
}

.rectagle4 {
  display: block;
  position: absolute;
  left: 9.5rem;
  top: 31rem;
  width: 5.5rem;
  height: 5.5rem;
  z-index: 1;
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    display: block;
    background-color: #ffff;
  }

  .download_tile {
    width: 80%;
  }

  .store_icon {
    width: 7rem;
    margin-right: 0.5rem;
    object-fit: contain;
  } 

  .app_icon {
    width: 9.5rem;
    margin-right: -0.5rem;
    object-fit: contain;
  } 

  .flex1 {
    font-size: 1.5rem;
    font-weight: bold;
    width: 70%;
    text-align: center;
    margin: auto;
  }
  .app_view {
    width: 80%;
    margin-top: 5rem;
  }
  .rectagle1 {
    position: absolute;
    left: 0;
    width: 4rem;
    height: 3.5rem;
  }

  .stats_container {
    height: 25rem;
  }

  .img1 {
    width: 11rem;
    height: 17rem;
    top: 3.5rem;
  }

  .img2 {
    width: 11rem;
    top: 16.5rem;
  }

  .rectagle3 {
    display: block;
    position: absolute;
    top: 16.3rem;
    width: 4.2rem;
    height: 4.2rem;
    z-index: 1;
  }

  .user_check {
    left: 1.2rem;
    top: 17.4rem;
    width: 2rem;
    z-index: 3;
  }

  .stat1 {
    left: 5rem;
    top: 0rem;
    font-size: 1rem;
  }

  .stat2 {
    left: 12rem;
    top: 9rem;
    font-size: 1rem;
  }

  .stat3 {
    left: 5rem;
    top: 16.5rem;
    font-size: 1rem;
  }
  .stat4 {
    left: 12rem;
    top: 23.5rem;
    font-size: 1rem;
  }

  .number1 {
    font-size: 1.5rem;
  }

  .number2 {
    font-size: 1.5rem;
  }

  .stat_name {
    font-size: 0.8rem;
  }

  .wrapp {
    white-space: nowrap;
  }

  .video {
    left: 8.3rem;
    top: 24rem;
    width: 2rem;
    z-index: 2;
  }

  .book {
    left: 8rem;
    top: 10rem;
    width: 2rem;
    z-index: 3;
  }

  .book_open {
    top: 1rem;
    left: 1rem;
    width: 2rem;
    z-index: 3;
  }
.rectagle2 {
  position: absolute;
  width: 4.2rem;
  height: 4.3rem;
  z-index: 1;
  left: 6.8rem;
  top: 9.1rem;
}
  .rectagle4 {
    left: 7rem;
    top: 23rem;
    width: 4.2rem;
    height: 4.2rem;
    z-index: 1;
  }
}
