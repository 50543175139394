@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
:root {
  --primary:#000A45;
  --fontcolor:#3E4365;
  --transition-style:all 0.3s ease-in-out;
  --font-primary: "Poppins", sans-serif;
}

h1 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h2 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h3 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h4 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h5 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h6 {
  margin: 0;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

:root * {
  scrollbar-width: thin;
}
:root *::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.625rem;
}
:root *::-webkit-scrollbar-track {
  background: #f7f7f7;
}
:root *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

* {
  margin: 0;
  padding: 0;
  border: medium none;
  outline: medium none !important;
  list-style: outside none none;
  font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}

html, body {
  height: 100%;
  font-family: var(--font-primary);
  color: #000629;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
}

a img {
  border: none;
}

a, a:hover, a:active, a:focus, button, .btn, .close {
  transition: var(--transition-style);
  outline: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

dl, ol, ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

::selection {
  background-color: var(--primary);
  color: #fff;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}

/*! ---placeholder and form-control--- !*/
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  background-color: #fff;
  border: 0.063rem solid #888;
  color: #000;
  font-size: 1rem;
  box-shadow: none;
  width: 100%;
  transition: var(--transition-style);
  border-radius: 1.563rem;
  height: 2.5rem;
  padding: 0.5rem 1.25rem;
  text-align: left;
  text-overflow: ellipsis;
}
.form-control::-webkit-input-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: var(--font-primary);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus::-webkit-input-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control::-moz-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: var(--font-primary);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus::-moz-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:-moz-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: var(--font-primary);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus:-moz-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:-ms-input-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: var(--font-primary);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus:-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:focus {
  background-color: #fff;
  color: #000;
  border-color: #888;
}
@media (max-width: 991px) {
  .form-control {
    height: 2.813rem;
  }
}

textarea.form-control {
  height: 100px;
  resize: none;
}

.form-group {
  margin-bottom: 1.375rem;
}
.form-group label {
  color: #000;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-group .error {
  margin: 0.313rem 0 0;
  color: #f00;
}

/*! ---placeholder and form-control--- !*/
span {
  position: relative;
}

p {
  font-size: 1rem;
  line-height: 1.8rem;
  margin: 0;
  font-family: var(--font-primary);
  font-weight: 400;
  color: #3E4365;
}
@media (max-width: 767px) {
  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

p + p {
  margin-top: 1rem;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
}
@media (max-width: 1400px) {
  h2 {
    font-size: 2.75rem;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 2.188rem;
  }
}

h3 {
  font-size: 1.875rem;
  font-weight: 700;
}
@media (max-width: 767px) {
  h3 {
    font-size: 1.75rem;
  }
}

.navbar-toggler {
  height: 1.875rem;
  padding: 0;
  border: none;
}
.navbar-toggler .navbar-toggler-icon {
  width: 2.188rem;
  display: inline-block;
  height: 0.25rem;
  position: relative;
  border-radius: 0.313rem;
  background: none;
}
.navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
  width: 2.188rem;
  transition: var(--transition-style);
  height: 0.25rem;
  background: var(--primary);
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  border-radius: 0.313rem;
  top: -0.813rem;
  margin-top: 0.625rem;
  transform: rotate(-45deg);
}
.navbar-toggler .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler.collapsed .navbar-toggler-icon {
  background: var(--primary);
}
.navbar-toggler.collapsed .navbar-toggler-icon:after {
  top: -1.313rem;
  transform: rotate(0);
}
.navbar-toggler.collapsed .navbar-toggler-icon:before {
  transform: rotate(0);
  top: 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1260px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1360px;
  }
}
/* ---------------------------- Menu section ---------------------------- */
.navbar {
  background: white;
  padding: 0.875rem 0;
}
.navbar .navbar-collapse {
  justify-content: flex-end;
}
.navbar .navbar-collapse .navbar-nav li {
  padding: 0 0.938rem;
}
.navbar .navbar-collapse .navbar-nav li a {
  color: #000A45;
  padding: 0 !important;
  font-size: 0.938rem;
}
.navbar .navbar-collapse .navbar-nav li a:hover, .navbar .navbar-collapse .navbar-nav li a.active {
  color: #9501F6;
}
.navbar .navbar-collapse .navbar-nav li:first-child {
  padding-left: 0;
}
.navbar .navbar-collapse .navbar-nav li:last-child {
  padding-right: 0;
}
.navbar .navbar-collapse .searchBtn {
  width: 2.188rem;
  height: 2.625rem;
  border: 0.063rem solid #000A45;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #3E4365;
  font-size: 0.75rem;
  margin-left: 2.188rem;
}
.navbar .navbar-collapse .login-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.625rem;
  width: 130px;
  padding: 0.313rem;
  margin-left: 1rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 1rem;
  background: #000A45;
  border-radius: 0.5rem;
}
.navbar .navbar-collapse .inputSearchBg {
  position: absolute;
  z-index: 2;
  top: 100%;
  padding: 0 0.625rem;
  background: white;
  border-radius: 0 0 0.625rem 0.625rem;
  transition: var(--transition-style);
  margin-top: -0.5rem;
  height: 0;
  overflow: hidden;
}
.navbar .navbar-collapse .inputSearchBg.active {
  height: 3.75rem;
  margin-top: 0;
  padding: 0.625rem;
}
@media (max-width: 991px) {
  .navbar .navbar-collapse .navbar-nav li {
    padding: 0 0.625rem;
  }
  .navbar .navbar-collapse .searchBtn {
    margin-left: 1.5rem;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-collapse .navbar-nav {
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
  .navbar .navbar-collapse .navbar-nav li {
    padding: 0.5rem 0;
    text-align: left;
  }
  .navbar .navbar-collapse .searchBtn {
    margin-left: 0;
  }
}

/* ---------------------------- Menu section ---------------------------- */
/* ---------------------------- Baner section ---------------------------- */
.abtBnr {
  height: 40rem;
  background-image: linear-gradient(150deg, #000a45 0%, #9501f7 70%);
  position: relative;
}
.abtBnr .bnrBg {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  pointer-events: none;
}
.abtBnr .bnrBg.lft {
  left: 7%;
  opacity: 0.1;
}
.abtBnr .bnrBg.rgt {
  right: 0;
}
.abtBnr .bnrBg.main {
  right: 3%;
  height: 75%;
  bottom: 0;
  margin: auto;
}
.abtBnr .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
}
.abtBnr__box * {
  color: white;
}
.abtBnr__box h1 {
  font-weight: 700;
  font-size: 3.5rem;
}
.abtBnr__box p {
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  .abtBnr .bnrBg.lft {
    left: 0;
  }
  .abtBnr .bnrBg.rgt {
    opacity: 0.3;
  }
}
@media (max-width: 991px) {
  .abtBnr .container {
    justify-content: center;
  }
  .abtBnr .abtBnr__box {
    text-align: center;
  }
  .abtBnr .abtBnr__box h1 {
    font-size: 3.75rem;
  }
  .abtBnr .bnrBg.main {
    right: 0;
    left: 0;
  }
}
@media (max-width: 767px) {
  .abtBnr {
    overflow: hidden;
  }
  .abtBnr .abtBnr__box h1 {
    font-size: 2rem;
  }
}
@media (max-width: 576px) {
  .abtBnr {
    height: 25rem;
  }
}

/* ---------------------------- Baner section ---------------------------- */
/* ---------------------------- About text section ---------------------------- */
.abtText {
  text-align: center;
  padding: 145px 0;
}
.abtText h2 {
  margin-bottom: 3rem;
}
.abtText p {
  max-width: 1030px;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 991px) {
  .abtText {
    padding: 5rem 0 2rem;
  }
  .abtText h2 {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 767px) {
  .abtText h2 {
    margin-bottom: 1.25rem;
    font-size: 1.75rem;
  }
}

/* ---------------------------- About text section ---------------------------- */
/* ---------------------------- Value box section ---------------------------- */
.valuesBg {
  text-align: center;
  padding-top: 150px;
}
.valuesBg h3 {
  margin-bottom: 4.063rem;
}
.valuesBg__Inbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 920px;
  height: 460px;
  margin: 0 auto;
}
.valuesBg__Inbox__row {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.valuesBg__Inbox__row .box {
  width: 100%;
  max-width: 283px;
  height: 212px;
  background: white;
  padding: 2rem 0.313rem;
  border-radius: 1.5rem;
  border: 0.063rem solid #9501F6;
  box-shadow: 0.875rem 0.875rem 2.438rem rgba(96, 6, 177, 0.14);
}
.valuesBg__Inbox__row .box h6 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #3E4365;
  margin-top: 1.563rem;
}
.valuesBg__Inbox__row .box.colred {
  background: #FBF4FF;
  opacity: 0.6;
  border: none;
  box-shadow: none;
}
.valuesBg__Inbox__row .box.colred.second {
  position: relative;
  z-index: 0;
}
@media (max-width: 1400px) {
  .valuesBg__Inbox__row .box h6 {
    font-size: 1rem;
  }
}
@media (max-width: 991px) {
  .valuesBg__Inbox__row .box {
    position: relative;
    z-index: 2;
  }
  .valuesBg__Inbox__row .box.colred {
    display: none;
  }
}
@media (max-width: 767px) {
  .valuesBg__Inbox__row .box {
    max-width: 240px;
  }
  .valuesBg__Inbox__row .box h6 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
.valuesBg__Inbox__row .bgImg {
  position: absolute;
}
.valuesBg__Inbox__row .bgImg.row1 {
  right: 5.125rem;
  bottom: -2.25rem;
}
.valuesBg__Inbox__row .bgImg.row2 {
  left: 5.125rem;
  top: -2.25rem;
  z-index: 1;
}
@media (max-width: 991px) {
  .valuesBg__Inbox__row .bgImg {
    z-index: 0;
  }
  .valuesBg__Inbox__row .bgImg.row1 {
    right: 5.625rem;
  }
  .valuesBg__Inbox__row .bgImg.row2 {
    left: 5.625rem;
  }
}
@media (max-width: 991px) {
  .valuesBg__Inbox {
    max-width: 610px;
  }
}
@media (max-width: 540px) {
  .valuesBg__Inbox {
    height: auto;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row {
    flex-direction: column;
    align-items: center;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row .box {
    max-width: 90%;
    margin-bottom: 1.25rem;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row .box:last-child {
    margin-bottom: 0;
  }
}
.valuesBg .textBox {
  max-width: 1030px;
  margin: 0 auto;
  padding: 100px 0 150px;
}
@media (max-width: 991px) {
  .valuesBg {
    padding-top: 5rem;
  }
  .valuesBg .textBox {
    padding: 5rem 0;
  }
}
@media (max-width: 767px) {
  .valuesBg h3 {
    margin-bottom: 3.125rem;
  }
}

/* ---------------------------- Value box section ---------------------------- */
/* ---------------------------- Our vision section ---------------------------- */
.ourVisionSec {
  text-align: center;
  background: #FBF4FF;
  padding-top: 150px;
}
.ourVisionSec .logoBig {
  max-width: 1030px;
  margin-bottom: 150px;
}
@media (max-width: 1200px) {
  .ourVisionSec .logoBig {
    max-width: 800px;
  }
}
@media (max-width: 991px) {
  .ourVisionSec .logoBig {
    max-width: 75%;
  }
}
@media (max-width: 767px) {
  .ourVisionSec .logoBig {
    max-width: 70%;
    margin-bottom: 6.25rem;
  }
}
.ourVisionSec .row {
  margin: 0;
}
.ourVisionSec .row [class*=col] {
  padding: 0;
}
.ourVisionSec .visionRow .imgBox {
  text-align: left;
}
.ourVisionSec .visionRow .contBox .box {
  max-width: 580px;
}
@media (max-width: 576px) {
  .ourVisionSec .visionRow .contBox .box {
    padding-left: 0;
  }
}
.ourVisionSec .missionRow .imgBox {
  text-align: right;
}
.ourVisionSec .missionRow .contBox {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.ourVisionSec .missionRow .contBox .box {
  max-width: 415px;
}
@media (max-width: 576px) {
  .ourVisionSec .missionRow .contBox {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .ourVisionSec .missionRow .contBox .box {
    padding-right: 0;
  }
  .ourVisionSec .missionRow .contBox .imgBox {
    display: flex;
    align-items: flex-end;
  }
}
.ourVisionSec .contBox {
  text-align: left;
}
.ourVisionSec .contBox .box {
  padding: 0 1.25rem;
}
.ourVisionSec .contBox h2 {
  margin-bottom: 2.813rem;
}
@media (max-width: 991px) {
  .ourVisionSec {
    padding-top: 5rem;
  }
  .ourVisionSec .contBox h2 {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 991px) {
  .ourVisionSec .contBox h2 {
    font-size: 1.375rem;
    margin-bottom: 0.875rem;
  }
}

/* ---------------------------- Our vision section ---------------------------- */
/* ---------------------------- How it started section ---------------------------- */
.howStartedSec {
  padding: 250px 0 150px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.howStartedSec .bgImg {
  position: absolute;
  top: 0;
  z-index: 0;
  bottom: 0;
  margin: auto;
}
.howStartedSec .bgImg.left {
  left: 0;
}
.howStartedSec .bgImg.right {
  right: 0;
}
@media (min-width: 1300px) {
  .howStartedSec .bgImg {
    height: 100%;
    max-height: 1100px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .bgImg.left {
    left: -20%;
  }
  .howStartedSec .bgImg.right {
    right: -20%;
  }
}
@media (max-width: 767px) {
  .howStartedSec .bgImg {
    display: none;
  }
}
.howStartedSec .textBox {
  position: relative;
  z-index: 1;
  max-width: 1030px;
  margin: 2rem auto 2.5rem;
  text-align: left;
}
.howStartedSec .textBoxBg {
  max-width: 825px;
  margin: 0 auto;
}
.howStartedSec .textBoxBg .textBox {
  margin: 2.5rem 0 0;
}
@media (max-width: 991px) {
  .howStartedSec .textBoxBg {
    padding: 0 0.938rem;
  }
}
.howStartedSec .founderSec {
  position: relative;
  /*padding:120px 0 150px;*/
}
.howStartedSec .founderSec__box {
  max-width: 825px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.howStartedSec .founderSec__box .box .desBox {
  padding: 1.563rem 0;
}
.howStartedSec .founderSec__box .box .desBox h6 {
  font-size: 1.5rem;
  font-weight: 700;
}
.howStartedSec .founderSec__box .box .desBox div {
  font-size: 1.25rem;
  font-weight: 500;
  color: #3E4365;
}
@media (max-width: 767px) {
  .howStartedSec .founderSec__box .box .desBox h6 {
    font-size: 0.875rem;
  }
  .howStartedSec .founderSec__box .box .desBox div {
    font-size: 0.625rem;
  }
}
@media (max-width: 767px) {
  .howStartedSec .founderSec__box .box {
    max-width: 45%;
  }
  .howStartedSec .founderSec__box .box img {
    max-height: 375px;
  }
}
@media (max-width: 576px) {
  .howStartedSec .founderSec__box .box img {
    max-height: 300px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .founderSec__box {
    padding: 0 0.938rem;
  }
}
@media (max-width: 420px) {
  .howStartedSec .founderSec__box {
    flex-direction: column;
    align-items: center;
  }
  .howStartedSec .founderSec__box .box {
    max-width: 100%;
  }
  .howStartedSec .founderSec__box .box img {
    max-height: 375px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .founderSec {
    padding-bottom: 0;
  }
}
@media (max-width: 991px) {
  .howStartedSec {
    padding: 5rem 0;
    padding-top: 4.375rem;
  }
  .howStartedSec .founderSec {
    padding-top: 5rem;
  }
}
@media (max-width: 767px) {
  .howStartedSec .founderSec {
    padding-top: 0;
  }
}

/* ---------------------------- How it started section ---------------------------- */
/* ---------------------------- Footer Social section ---------------------------- */
.footerSocial {
  text-align: center;
  padding-bottom: 120px;
}
.footerSocial__Box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: 130px;
  background: #A83EEE;
  border-radius: 1.5rem;
}
.footerSocial ul {
  display: flex;
}
.footerSocial ul li {
  margin-right: 0.938rem;
}
.footerSocial ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: white;
  border-radius: 50%;
  border: 0.063rem solid #9501F6;
  color: #000A45;
  font-size: 1.25rem;
}
.footerSocial ul li a:hover {
  color: #9501F6;
}
.footerSocial ul li:last-child {
  margin-right: 0;
}
@media (max-width: 991px) {
  .footerSocial {
    padding-bottom: 5rem;
  }
  .footerSocial .footerSocial__Box {
    height: 6.25rem;
    max-width: 400px;
  }
}

/* ---------------------------- Footer Social section ---------------------------- */
/* ---------------------------- Footer section ---------------------------- */
.footer {
  padding: 5.625rem 0;
  background: #000A45;
}
.footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .container ul {
  display: flex;
}
.footer .container li {
  position: relative;
  padding: 0 2rem;
}
.footer .container li a {
  color: white;
  font-size: 1.125rem;
}
.footer .container li a:hover {
  color: #8102DF;
}
@media (max-width: 767px) {
  .footer .container li a {
    font-size: 0.875rem;
  }
}
.footer .container li:after {
  content: "";
  background: #8102DF;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  position: absolute;
  right: -1rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.footer .container li:first-child {
  padding-left: 0;
}
.footer .container li:last-child {
  padding-right: 0;
}
.footer .container li:last-child:after {
  display: none;
}
@media (max-width: 1200px) {
  .footer .container {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .footer .container ul {
    margin-top: 0.875rem;
  }
}
@media (max-width: 1400px) {
  .footer {
    padding: 2rem 0;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 1.5rem 0;
  }
  .footer img {
    max-width: 150px;
  }
}
@media (max-width: 991px) {
  .footer .container ul {
    flex-direction: column;
  }
  .footer .container ul li {
    padding: 0.5rem 0.75rem;
  }
  .footer .container ul li:first-child {
    padding-left: 0.75rem;
  }
  .footer .container ul li:last-child:after {
    display: inline-block;
  }
  .footer .container ul li:after {
    width: 0.5rem;
    height: 0.5rem;
    left: -0.25rem;
    right: auto;
  }
}

/* ---------------------------- Footer section ---------------------------- */

/*# sourceMappingURL=mainStyle.css.map */
