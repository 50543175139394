.container {
  position: relative;
  display: flex;
  /* margin-top: 6rem; */
  margin-top: 2rem;
}

.flex1 {
  font-size: 2rem;
  font-family: "poppinsBold";
  width: 50%;
  text-align: initial;
  margin-left: 7rem;
  margin-top: 5rem;
  margin-right: 3rem;
}

.stats_container {
  position: relative;
}

.stat1 {
  /* text-align: left; */
  position: absolute;
  /* left: 8rem; */
  top: 0.5rem;
  font-size: 1.5rem;
  font-family: "poppinsSmall";
}

.stat2 {
  position: absolute;
  text-align: left;
  top: 12.5rem;
  font-size: 1.5rem;
}

.stat3 {
  position: absolute;
  text-align: left;
  top: 12.5rem;
  font-size: 1.5rem;
}


.number2 {
  font-family: "poppinsRegular" !important;
  color: #000629;
  font-size: 1.2rem;
}

.stat_name {
  color: #3e4365;
  font-size: 1.4rem;
  padding-right: 9rem;
  font-family: "poppinsRegular";
}

.address_name {
  color: #000629;
  font-size: 1.5rem;
  font-family: 'poppinsSemiBold' !important;
  padding-right: 9rem;
  margin-left: 3rem;
}

.phone {
  width: 1.4rem;
}

.email_icon {
  width: 1.4rem;
}

.phone_ui {
  margin-right: 0.4rem;
  bottom: 30px;
  right: 20px;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  background: #9501f6;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.img1 {
  position: absolute;
  left: 5rem;
  width: 18rem;
  height: 25rem;
  top: -2rem;
}

.img2 {
  position: absolute;
  left: 5rem;
  width: 18rem;
  top: 17rem;
  opacity: 0.3;
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    display: block;
    background-color: #ffff;
  }

  .address_name {
    color: #000629;
    font-size: 1.2rem;
    font-family: 'poppinsSemiBold' !important;
    padding-right: 0rem !important;
  }

  .flex1 {
    font-size: 1.5rem;
    font-weight: bold;
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .stats_container {
    height: 16rem;
  }

  .stat1 {
    left: 0rem;
    top: 0rem;
    font-size: 1rem;
  }

  .stat2 {
    left: 1rem;
    top: 8rem;
    font-size: 1rem;
  }

  .stat3 {
    left: 1rem;
    top: 7rem;
    font-size: 1rem;
  }

  .number2 {
    font-family: "poppinsRegular" !important;
    color: #000629;
    font-size: 1.1rem;
  }

  .stat_name {
    color: #3e4365;
    font-size: 1rem;
    padding-right: 0rem;
    font-family: "poppinsRegular";
  }

  .contact_name {
    font-size: 1.5rem;
    text-align: inherit;
    font-family: "poppinsBold";
  }

  .div_component {
    padding: 0rem !important;
    padding-left: 1rem !important; 
    padding-right: 0rem !important;
    width: 93% !important;
    /* margin-top: 2rem; */
  }

  .form {
    background-color: #edd2fe;
    border: 1px solid #edd2fe;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 1rem !important;
  }

  .img1 {
    left: 2rem;
    width: 10rem;
    top: -2rem;
    height: 16rem;
  }

  .img2 {
    /* display: none; */
    position: absolute;
    left: 2rem;
    width: 10rem;
    top: 10rem;
    opacity: 0.3;
  }
}

.div_component {
padding: 1rem;
padding-right: 5rem;
width: 50%;
}

form {
  background-color: #edd2fe;
  border: 1px solid #edd2fe;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 80px;
}

.input {
  border: 1px solid #000a45;
  border-radius: 0.8rem;
  font-family: "poppinsRegular";
  color: #3c3c3c;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 3.3rem;
}

.message_input {
  border: 1px solid #000a45;
  border-radius: 0.8rem;
  font-family: "poppinsRegular";
  color: #3c3c3c;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 6.3rem;
}

.form_margin {
  margin-top: 2rem;
}

.label {
  text-align: left;
  color: #3d3d3d;
  display: block;
  font-family: "poppinsMedium";
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.button {
  display: flex;
  background-color: #000a45;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
  font-size: 14px;
  font-family: "poppinsBold";
  place-content: center;
  width: 9rem;
  margin-top: 2rem;
}

.contact_name {
  font-size: 2.2rem;
  font-family: "poppinsBold";
}