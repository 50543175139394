.description {
  width: 70%;
  margin: 3rem auto;
}

.header {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2.5rem;
  font-family: "poppinsBold";
}

.first_image {
  position: absolute;
  top: 1rem;
  right: 6rem;
  padding: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.sec_image {
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.third_image {
  position: absolute;
  top: 22rem;
  right: 6rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.container {
  position: relative;
  padding: 1rem 0;
  margin-top: 4rem;
}

.description > * {
  margin: 2rem 0;
  text-align: initial;
}

.img1 {
  position: absolute;
  left: 5rem;
  width: 18rem;
  height: 25rem;
  top: -4rem;
}

.img2 {
  position: absolute;
  left: 5rem;
  width: 1.8rem;
  top: 15rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .header {
    font-size: 2rem;
  }
  .description {
    width: 80%;
  }
  .first_image,
  .sec_image,
  .third_image {
    display: none;
  }
  .container {
    margin-top: 2rem;
    padding: 0.5rem;
  }
  .description {
    width: 90%;
    margin: 2rem auto;
    font-size: 0.9rem;
  }
  .img1 {
    left: 2rem;
    width: 10rem;
    top: -2rem;
    height: 16rem;
  }
  .img2 {
    display: none;
  }
}
