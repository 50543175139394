.container {
  height: 2.5rem;
}

.right_img {
  width: 30%;
  height: 100%;
  object-fit: cover;
  float: right;
}

.left_img {
  width: 30%;
  height: 100%;
  object-fit: cover;
  float: left;
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    height: 2rem;
    background-color: #f9f9f9;
  }

  .right_img {
    width: 40%;
    height: 100%;
  }

  .left_img {
    width: 40%;
    height: 100%;
  }
}
