.container {
  background: linear-gradient(to bottom right, #000a45, #9501f7);
  height: 40rem;
  display: flex;
  /* margin-top: 4rem; */
  position: relative;
}

.texts_container {
  text-align: left;
  width: 60%;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.app_store {
  margin-top: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.store_icon {
  width: 8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  border-radius: 0.5rem;
} 

.play_store {
  display: flex;
  cursor: pointer;
  color: #ffff;
}

.app_icon {
  width: 9.8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  border-radius: 0.5rem;
} 

.info_container {
  position: absolute;
  top: 50%;
  transform: translate(10%, -50%);
}

.dr_image {
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 30px;
}

.img_container {
  position: relative;
}

.text1 {
  font-size: 2.5rem;
  margin: 0.5rem 0;
}

.text2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
  font-family: "poppinsBold";
}

.text3 {
  margin: 0.5rem 0;
}

.button_container {
  display: flex;
  margin-top: 2rem;
}

.button_single_container {
  margin: 0 1rem 0.5rem 0;
}

.img1 {
  position: absolute;
  width: 16rem;
  left: 20rem;
}

.img2 {
  position: absolute;
  width: 15rem;
  bottom: -0.2rem;
  left: 5rem;
}

.ddd {
  background-size: cover;
  top: 2rem;
  width: 100%;
  height: 100%;
  
  margin-top: 4rem;

}

@media (min-width: 320px) and (max-width: 480px) {
  .text1 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }

  .text2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  .text3 {
    font-size: 0.8rem;
  }

  .store_icon {
    width: 7rem;
    margin-right: 0.5rem;
    object-fit: contain;
  } 

  .app_icon {
    width: 8rem;
    object-fit: contain;
    margin-left: -0.5rem;
  } 

  .button_container {
    display: block;
    margin-top: 2rem;
  }

  .info_container {
    /* top: 35%; */
    width: 90%;
  }

  .container {
    /* display: block; */
    position: relative;
    height: 25rem;
  }

  .img_container {
    position: static;
  }

  .dr_image {
    height: 60%;
    position: absolute;
    right: 1rem;
    bottom: 30px;
    left: auto;
  }

  .img1 {
    width: 9rem;
    left: 10rem;
    height: 16rem;
  }

  .img2 {
    width: 8rem;
    bottom: 0rem;
    left: 2rem;
  }
}
