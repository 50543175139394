@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "poppinsRegular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "poppinsRegular";
  src: local("poppins"), url(/static/media/PoppinsRegular.9171031e.ttf) format("truetype");
}

@font-face {
  font-family: "poppinsBold";
  src: local("poppinsBold"), url(/static/media/PoppinsBold.9f0cd50b.ttf) format("truetype");
}

@font-face {
  font-family: "poppinsMedium";
  src: local("poppinsMedium"),
    url(/static/media/PoppinsMedium.2d6ae121.ttf) format("truetype");
}

@font-face {
  font-family: "poppinsSemiBold";
  src: local("poppinsSemiBold"),
    url(/static/media/PoppinsSemiBold.e67a02ce.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #000a45; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  background: #9501f7;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.back-to-top:hover {
  background: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .back-to-top {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .swiper_yt-pagination {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 45%;
    height: 1.8rem;
    border: 1px solid #a83eee;
  }

  .swiper_cl-pagination {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 30%;
    height: 1.8rem;
    border: 1px solid #a83eee;
  }

  .swiper {
    width: 100% !important;
  }

  .pagination_container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination_mobile {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 30%;
    bottom: 2.5rem !important;
    justify-content: center;
    left: 35%;
    border: 1px solid #a83eee;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    margin: 0 0.2rem;
  }

  .pagination_container-yt {
    width: 100% !important;
    position: relative !important;
    left: 0 !important;
    margin-top: 1.5rem !important;
  }
}

.swiper {
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.swiper_yt {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper_cl {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6BAMAAAATlVcbAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUdwTAAQQAAKRQAIQwAKRQAKRAAJRQAKRQAJRAAJRQAIRAAJRQAJRQAKRAAGRAAKRT+BC7oAAAAPdFJOUwAQgCCQ79/Pv6ZAYHBQMDy2GrQAAAILSURBVDjLjVRNS9xQFL1xWmntKEHaWjfiP+hgVexK/QVRFxah8ES66WrsL5hBWtwIM4K4zRREmJUDltadLl2KdtFFsV0VutMZJ8avnr5nMnk3mSR6F8kLJ/e9c+959xAFkfmyLvDm4xHFxUYeXrz/3Q7uIIjzWhTcBJorRzkaOPwEOBF4DJj77K+rwEWOg1mBGTP4GsrjLUctnJnscxiY0F8v4FRCB43CDf42ClgN0zAsfGitH6MeLaEDrj51sK38l62Ts+2pKvnaW4xwgkHYTa9m2zFj0OcoqtcTNOJE6cGNenXGbiy5Ouq567Cm7mv2z6C0KHDGos5YF9X+/NiSLt0Ql0QPUGZoH6vdOlOkuACy5UHylqQ13gwxZckPkaNfblgdnfxIkrYiTdbJWdmI0g0lJHdLvoV/lJCcwTyJU0pINjBFOLn9rwAWvrQS8tBuDuI8ghp5jtYDVDTSzs03kjj3YJHslHqLZF0n9apD9mo3pc8HUiMzRaN0fZPvhn1B1IVpPgE6NQOlTynxTi6q+8xp/dhjE167cxa6cBkHZnDl3fCEGSz781uO29if3/TZT/cN2e40z5HNnYyIbGu/on44IdukP8zrbn2Sw09DPqk8dkHDvSLssfRaerKvs1EVcMMH0Vfp7UvHJr36qby9Eq3hm77sbq29O39b4/CuEqvK92WB2bVtum/8B6hdRfdagD69AAAAAElFTkSuQmCC) no-repeat center center;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  /* bottom: 10% !important;
  left: 2% !important; */
}

.pagination_container {
  width: 100%;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  margin: 0 0.2rem;
}

.swiper-slide_yt {
  width: 100%;
  /* display: flex; */
  /* padding: 2rem 0;
  justify-content: space-between; */
}

.db_cont {
  display: flex;
}
.swiper-slide {
  border-radius: 1rem;
  overflow: hidden;
}
.vieo_deck {
  margin-right: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  display: inline-flex;
}

.col_cont {
  display: block;
}

.up-arraow {
  width: 1.5rem;
}

.swiper-button-next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6BAMAAAATlVcbAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUdwTAAQQAAKRQAIQwAKRQAKRAAJRQAKRQAJRAAJRQAIRAAJRQAJRQAKRAAGRAAKRT+BC7oAAAAPdFJOUwAQgCCQ79/Pv6ZAYHBQMDy2GrQAAAILSURBVDjLjVRNS9xQFL1xWmntKEHaWjfiP+hgVexK/QVRFxah8ES66WrsL5hBWtwIM4K4zRREmJUDltadLl2KdtFFsV0VutMZJ8avnr5nMnk3mSR6F8kLJ/e9c+959xAFkfmyLvDm4xHFxUYeXrz/3Q7uIIjzWhTcBJorRzkaOPwEOBF4DJj77K+rwEWOg1mBGTP4GsrjLUctnJnscxiY0F8v4FRCB43CDf42ClgN0zAsfGitH6MeLaEDrj51sK38l62Ts+2pKvnaW4xwgkHYTa9m2zFj0OcoqtcTNOJE6cGNenXGbiy5Ouq567Cm7mv2z6C0KHDGos5YF9X+/NiSLt0Ql0QPUGZoH6vdOlOkuACy5UHylqQ13gwxZckPkaNfblgdnfxIkrYiTdbJWdmI0g0lJHdLvoV/lJCcwTyJU0pINjBFOLn9rwAWvrQS8tBuDuI8ghp5jtYDVDTSzs03kjj3YJHslHqLZF0n9apD9mo3pc8HUiMzRaN0fZPvhn1B1IVpPgE6NQOlTynxTi6q+8xp/dhjE167cxa6cBkHZnDl3fCEGSz781uO29if3/TZT/cN2e40z5HNnYyIbGu/on44IdukP8zrbn2Sw09DPqk8dkHDvSLssfRaerKvs1EVcMMH0Vfp7UvHJr36qby9Eq3hm77sbq29O39b4/CuEqvK92WB2bVtum/8B6hdRfdagD69AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
}

.swiper-button-prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA2UExURUdwTAAKRQAJRQAJRAAKRAAKRQAJRAAQQAAKRQAIQwAJRQAKRQAIRAAJRAAKRAAGRAAKRQAKRQcDvZAAAAARdFJOUwDP33DvkL8QgCBgn0CvUDDfX656MAAAAfVJREFUSMetV9miqyAMBFnCjv7/zx5A2uuSKNqbtwpDkkkIU8Yws07FSS7LIrlWybJRC2pa9jb7PAJ0esGMu1uPq0OpRbI1TsjJaLm6vgTb5lH6AIcDfQNHOmnRiDGAHWpqOFIQSFVPVkBFtC5jS1CDna+YtNVxPB8NvHz3cMkhVMf8tCeWr+a2cqbs0kieZqDq5pSvGESu2C3PVhLcIVZKLO0u0RkGoTBt03UliPHbkcvu8PkxjYfbKZ3/OZ1Rp0KjHQ+Fmb7ACXYLmZJiWX9jt0QZIqPchs62J5AcyAKpTlJ4hmRhJcqiGV0iW8Qlx3Ru6DskY7pxrBB+75C151U7ID1F1lBjq2p+iqwEFZ5KxvAU2bktG09IzPbDsKGOUE1A9T1U4EiZzlB57OCRXKHlOp2afwBbrgxvw+VVXXXrJvG4m0zrJveyh9NaXXiK7dy+v6812RdTwvcjJAw+Td8O7ldG0xNxofjl3zmMui1zWFBPtPt5+tdslzyMrJm6bYUfvXRx/7768XC37+sPr/ovWmKdK2MKJqK6Sd3oJo83J1TlNF/pglzVmgZaI9orqUa2TlOms8HAYOSVMi31jW1w+sP9heCbmtaXMsd9VLhJuWZlbRIfFR5utT/Hh78baZjsj/84JhXGb0dSmjdipqicZf/b/gD1Jirezr5eOQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next-yt {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6BAMAAAATlVcbAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUdwTAAQQAAKRQAIQwAKRQAKRAAJRQAKRQAJRAAJRQAIRAAJRQAJRQAKRAAGRAAKRT+BC7oAAAAPdFJOUwAQgCCQ79/Pv6ZAYHBQMDy2GrQAAAILSURBVDjLjVRNS9xQFL1xWmntKEHaWjfiP+hgVexK/QVRFxah8ES66WrsL5hBWtwIM4K4zRREmJUDltadLl2KdtFFsV0VutMZJ8avnr5nMnk3mSR6F8kLJ/e9c+959xAFkfmyLvDm4xHFxUYeXrz/3Q7uIIjzWhTcBJorRzkaOPwEOBF4DJj77K+rwEWOg1mBGTP4GsrjLUctnJnscxiY0F8v4FRCB43CDf42ClgN0zAsfGitH6MeLaEDrj51sK38l62Ts+2pKvnaW4xwgkHYTa9m2zFj0OcoqtcTNOJE6cGNenXGbiy5Ouq567Cm7mv2z6C0KHDGos5YF9X+/NiSLt0Ql0QPUGZoH6vdOlOkuACy5UHylqQ13gwxZckPkaNfblgdnfxIkrYiTdbJWdmI0g0lJHdLvoV/lJCcwTyJU0pINjBFOLn9rwAWvrQS8tBuDuI8ghp5jtYDVDTSzs03kjj3YJHslHqLZF0n9apD9mo3pc8HUiMzRaN0fZPvhn1B1IVpPgE6NQOlTynxTi6q+8xp/dhjE167cxa6cBkHZnDl3fCEGSz781uO29if3/TZT/cN2e40z5HNnYyIbGu/on44IdukP8zrbn2Sw09DPqk8dkHDvSLssfRaerKvs1EVcMMH0Vfp7UvHJr36qby9Eq3hm77sbq29O39b4/CuEqvK92WB2bVtum/8B6hdRfdagD69AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
}

.swiper-button-prev-yt {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA2UExURUdwTAAKRQAJRQAJRAAKRAAKRQAJRAAQQAAKRQAIQwAJRQAKRQAIRAAJRAAKRAAGRAAKRQAKRQcDvZAAAAARdFJOUwDP33DvkL8QgCBgn0CvUDDfX656MAAAAfVJREFUSMetV9miqyAMBFnCjv7/zx5A2uuSKNqbtwpDkkkIU8Yws07FSS7LIrlWybJRC2pa9jb7PAJ0esGMu1uPq0OpRbI1TsjJaLm6vgTb5lH6AIcDfQNHOmnRiDGAHWpqOFIQSFVPVkBFtC5jS1CDna+YtNVxPB8NvHz3cMkhVMf8tCeWr+a2cqbs0kieZqDq5pSvGESu2C3PVhLcIVZKLO0u0RkGoTBt03UliPHbkcvu8PkxjYfbKZ3/OZ1Rp0KjHQ+Fmb7ACXYLmZJiWX9jt0QZIqPchs62J5AcyAKpTlJ4hmRhJcqiGV0iW8Qlx3Ru6DskY7pxrBB+75C151U7ID1F1lBjq2p+iqwEFZ5KxvAU2bktG09IzPbDsKGOUE1A9T1U4EiZzlB57OCRXKHlOp2afwBbrgxvw+VVXXXrJvG4m0zrJveyh9NaXXiK7dy+v6812RdTwvcjJAw+Td8O7ldG0xNxofjl3zmMui1zWFBPtPt5+tdslzyMrJm6bYUfvXRx/7768XC37+sPr/ovWmKdK2MKJqK6Sd3oJo83J1TlNF/pglzVmgZaI9orqUa2TlOms8HAYOSVMi31jW1w+sP9heCbmtaXMsd9VLhJuWZlbRIfFR5utT/Hh78baZjsj/84JhXGb0dSmjdipqicZf/b/gD1Jirezr5eOQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  position: absolute !important;
  left: 57% !important;
  top: 80% !important;
}

.swiper-button-prev {
  position: absolute !important;
  left: 50% !important;
  top: 80% !important;
}

.swiper-button-next-yt {
  position: absolute !important;
  left: 13% !important;
  bottom: -25% !important;
  height: 4rem;
  width: 1.7rem;
  cursor: pointer;
}

.swiper-button-prev-yt {
  position: absolute !important;
  left: 5% !important;
  bottom: -25% !important;
  height: 4rem;
  width: 1.7rem;
  cursor: pointer;
}

.pagination_container-yt {
  width: 10%;
  position: absolute;
  left: 5%;
  bottom: -17%;
}

.swiper-pagination-bullet-active {
  background: #000a45 !important;
}

.swiper-pagination {
  top: 76.5% !important;
  left: 53%;
}

.swiper-pagination_mobile {
  background-color: #f5e5ff !important;
  border-radius: 2rem;
  margin: auto;
  height: 1.8rem;
  width: 30%;
  bottom: 2.5rem !important;
  justify-content: center;
  left: 35%;
  border: 1px solid #a83eee;
}

.vieo_deck {
 cursor: pointer;
}

.up_arrow {
  position: fixed; 
  cursor: pointer;
  bottom: 40px; 
  right: 40px; 
  color: rgb(255, 255, 255); 
  background-color: #9501f7;
  z-index: 1; width: 45px; 
  text-align: center; 
  height: 45px; 
  border-radius: 50%; 
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px; 
  display: inline;
}

.up_arrow:hover {
  background: #9d3ddd;
}
.styles_container__SiaP0 {
  background: linear-gradient(to bottom right, #000a45, #9501f7);
  height: 40rem;
  display: flex;
  /* margin-top: 4rem; */
  position: relative;
}

.styles_texts_container__1jHt4 {
  text-align: left;
  width: 60%;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.styles_app_store__ssLld {
  margin-top: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.styles_store_icon__10z3R {
  width: 8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  border-radius: 0.5rem;
} 

.styles_play_store__3aSMv {
  display: flex;
  cursor: pointer;
  color: #ffff;
}

.styles_app_icon__BXOSP {
  width: 9.8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  border-radius: 0.5rem;
} 

.styles_info_container__24J7a {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(10%, -50%);
          transform: translate(10%, -50%);
}

.styles_dr_image__g1Pco {
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 30px;
}

.styles_img_container__1hlZX {
  position: relative;
}

.styles_text1__1XSAW {
  font-size: 2.5rem;
  margin: 0.5rem 0;
}

.styles_text2__oL2M9 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
  font-family: "poppinsBold";
}

.styles_text3__HkXQh {
  margin: 0.5rem 0;
}

.styles_button_container__KUeo7 {
  display: flex;
  margin-top: 2rem;
}

.styles_button_single_container__12bYA {
  margin: 0 1rem 0.5rem 0;
}

.styles_img1__1llO0 {
  position: absolute;
  width: 16rem;
  left: 20rem;
}

.styles_img2__nSfSn {
  position: absolute;
  width: 15rem;
  bottom: -0.2rem;
  left: 5rem;
}

.styles_ddd__1FLOG {
  background-size: cover;
  top: 2rem;
  width: 100%;
  height: 100%;
  
  margin-top: 4rem;

}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_text1__1XSAW {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }

  .styles_text2__oL2M9 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  .styles_text3__HkXQh {
    font-size: 0.8rem;
  }

  .styles_store_icon__10z3R {
    width: 7rem;
    margin-right: 0.5rem;
    object-fit: contain;
  } 

  .styles_app_icon__BXOSP {
    width: 8rem;
    object-fit: contain;
    margin-left: -0.5rem;
  } 

  .styles_button_container__KUeo7 {
    display: block;
    margin-top: 2rem;
  }

  .styles_info_container__24J7a {
    /* top: 35%; */
    width: 90%;
  }

  .styles_container__SiaP0 {
    /* display: block; */
    position: relative;
    height: 25rem;
  }

  .styles_img_container__1hlZX {
    position: static;
  }

  .styles_dr_image__g1Pco {
    height: 60%;
    position: absolute;
    right: 1rem;
    bottom: 30px;
    left: auto;
  }

  .styles_img1__1llO0 {
    width: 9rem;
    left: 10rem;
    height: 16rem;
  }

  .styles_img2__nSfSn {
    width: 8rem;
    bottom: 0rem;
    left: 2rem;
  }
}

.styles_description__HVEh1 {
  width: 70%;
  margin: 3rem auto;
}

.styles_header__UcZGi {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2.5rem;
  font-family: "poppinsBold";
}

.styles_first_image__3omOI {
  position: absolute;
  top: 1rem;
  right: 6rem;
  padding: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.styles_sec_image__1HxZX {
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.styles_third_image__ccf5d {
  position: absolute;
  top: 22rem;
  right: 6rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.styles_container__3tGla {
  position: relative;
  padding: 1rem 0;
  margin-top: 4rem;
}

.styles_description__HVEh1 > * {
  margin: 2rem 0;
  text-align: left;
  text-align: initial;
}

.styles_img1__1mOku {
  position: absolute;
  left: 5rem;
  width: 18rem;
  height: 25rem;
  top: -4rem;
}

.styles_img2__1E5wd {
  position: absolute;
  left: 5rem;
  width: 1.8rem;
  top: 15rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_header__UcZGi {
    font-size: 2rem;
  }
  .styles_description__HVEh1 {
    width: 80%;
  }
  .styles_first_image__3omOI,
  .styles_sec_image__1HxZX,
  .styles_third_image__ccf5d {
    display: none;
  }
  .styles_container__3tGla {
    margin-top: 2rem;
    padding: 0.5rem;
  }
  .styles_description__HVEh1 {
    width: 90%;
    margin: 2rem auto;
    font-size: 0.9rem;
  }
  .styles_img1__1mOku {
    left: 2rem;
    width: 10rem;
    top: -2rem;
    height: 16rem;
  }
  .styles_img2__1E5wd {
    display: none;
  }
}

.styles_container__cHjxF {
  background: linear-gradient(to bottom right, #000a45, #9501f7);
  height: 22rem;
  display: flex;
  position: relative;
  color: #ffff;
}
.styles_top_part__1A3Z0 {
  height: 10rem;
  position: relative;
  overflow: hidden;
}

.styles_rectangle__1GLuL {
  position: absolute;
  right: 0;
  width: 9rem;
}

.styles_rectangle18__1wyH_ {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 11rem;
}

.styles_rectangle13__3jzYC {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 6.8rem;
}

.styles_rectangle19__3Jcbv {
  position: absolute;
  left: 0;
  width: 5rem;
  top: 15rem;
  left: 15rem;
}

.styles_rectangle20__19Sg0 {
  position: absolute;
  width: 5rem;
  bottom: 3rem;
  right: 29rem;
}

.styles_rectangle16__3ncin {
  position: absolute;
  width: 5rem;
  bottom: 0rem;
  right: 0rem;
}

.styles_top_img__3A3W4 {
  width: 17rem;
  position: absolute;
  right: 4.3rem;
  top: -5rem;
}
.styles_flex1_content__1C7sg {
  position: relative;
  top: 50%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60%;
  text-align: left;
  text-align: initial;
}

.styles_flex1_content_heading__2a_0E {
  font-family: "poppinsBold";
  font-size: 2rem;
  margin: 0.5rem 0;
}

.styles_flex1_content_description__2OSfa {
  margin-top: 1.5rem;
}

.styles_flex1__2j64r {
  width: 60%;
}
.styles_flex2__b2KSJ {
  width: 40%;
  position: relative;
}

.styles_flex2_content__aoJAC {
  border: 1px solid #e5eaff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 3rem;
  border-radius: 4rem;
  width: 60%;
}

.styles_download_title__S25wE {
  border: 1px solid #ffff;
  padding: 0.2rem;
  border-radius: 0.7rem;
  margin: 0.5rem 0.5rem 2rem 0.5rem;
  width: 50%;
  margin: auto auto 2rem auto;

}

.styles_store_button_container__RzcXW {
  margin: 0.5rem;
  /* width: 50%; */
  margin: 1rem auto;
}

.styles_app_store__W5qoU {
  display: flex;justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.styles_store_icon__2FmXF {
  width: 9.7rem;
  /* height: 2.9rem; */
  border-radius: 0.6rem;
}

.styles_app_icon__1Y22T {
  width: 11rem;
  /* height: 4.2rem; */
  border-radius: 0.6rem;
  margin-left: -0.1rem;max-width: inherit;
}
.styles_main_heading__FlvrE {
  font-size: 2rem;
  font-family: "poppinsBold";
  margin: 2rem 0;
}

.styles_sub_heading__2w8uo {
  width: 40%;
  margin: auto;
}

.styles_learning_list_container__BwbVq {
  display: flex;
  justify-content: space-between;
  margin: 3rem 1rem;
  flex-wrap: wrap;
  background-color: #ffff;
  z-index: 5;
}

.styles_learning_item__CwjHq {
  width: 20%;
  /* height: 2.8rem; */
  border: 1px solid #425cc7;
  border-radius: 0.9rem;
  padding: 1rem;
  display: flex;
  margin: 0.5rem;
}

.styles_learning_item_name__X8u9Q {
  margin: auto 0 auto 1.5rem;
  text-align: left;
}

.styles_learning_item_icon__3cmwX {
  width: 3.5rem;
  object-fit: contain;
}

.styles_container__1I45d {
  margin: 7rem 3rem;
}

.styles_top_image__1kZFT {
  position: absolute;
  width: 16rem;
  right: 0;
  z-index: -1;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_learning_list_container__BwbVq {
    display: block;
  }

  .styles_learning_item__CwjHq {
    width: 70%;
    height: 4rem;
    padding: 0.5rem;
    margin: 1rem auto;
  }

  .styles_main_heading__FlvrE {
    font-size: 1.5rem;
  }

  .styles_container__1I45d {
    margin: 4rem 0;
  }

  .styles_learning_item_icon__3cmwX {
    width: 3rem;
    object-fit: contain;
    margin-left: 0.5rem;
  }

  .styles_learning_item_name__X8u9Q {
    margin: auto 0 auto 2rem;
    text-align: left;
  }
}

.styles_container__2jcgm {
  display: flex;
  margin-top: 5rem;
}

.styles_flex1__SUQ3X {
  font-size: 2rem;
  font-family: "poppinsBold";
  width: 50%;
  text-align: left;
  text-align: initial;
  margin-left: 7rem;
}

.styles_poppinsText__3JwYe {
  font-family: "poppinsMedium";
}

.styles_app_view__2Nzs7 {
  width: 70%;
}

.styles_download_tile__Xp0JU {
  border: 1px solid #e5eaff;
  border-radius: 1rem;
  width: 70%;
  margin: 2rem auto;
  color: #3e4365;
  background-color: #ffff;
}

.styles_app_store__dy0Be {
  /* width: 50%; */
  display: flex;
  background-color: #ffff;
  /* padding: 0.6rem 1rem; */
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.styles_play_store__34i93 {
  /* width: 50%; */
  display: flex;
  background-color: #ffff;
  /* padding: 0.6rem 1rem; */
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
}

.styles_store_icon__3Nq2b {
  width: 8rem;
  margin-right: 0.5rem;
  object-fit: contain;
  margin-top: 0.5rem;
} 

.styles_app_icon__CcScv {
  width: 9.6rem;
  margin-right: 0.5rem;
  object-fit: contain;
} 

.styles_button_container__I5w3n {
  display: flex;
  padding: 1rem;
  justify-content: space-around;
}

.styles_download_tile_title__2rw35 {
  margin: 2rem 0;
  font-weight: bold;
}

.styles_img1__2wfv9 {
  display: block;
  width: 15rem;
  height: 22rem;
  position: absolute;
  top: 5.5rem;
}

.styles_img2__BYp_D {
  display: block;
  position: absolute;
  width: 15rem;
  top: 22rem;
}

.styles_rectagle3__2l2H6 {
  display: block;
  position: absolute;
  width: 15rem;
  top: 22rem;
  width: 5.5rem;
  height: 5.5rem;
  z-index: 1;
}

.styles_stats_container__1wZFF {
  position: relative;
  margin-top: 2rem;
}

.styles_rectagle1__1yQpE {
  width: 5.5rem;
  height: 5.5rem;
}

.styles_stat1__dgQ7x {
  text-align: left;
  position: absolute;
  left: 8rem;
  top: 0.5rem;
  font-size: 1.5rem;
}

.styles_stat2__3K96I {
  position: absolute;
  text-align: left;
  left: 17rem;
  top: 12.5rem;
  font-size: 1.5rem;
}

.styles_stat3__20Dg8 {
  position: absolute;
  text-align: left;
  left: 7rem;
  top: 21.5rem;
  font-size: 1.5rem;
}
.styles_stat4__2pgIE {
  position: absolute;
  text-align: left;
  left: 16rem;
  top: 31rem;
  font-size: 1.5rem;
}

.styles_number1__AJRNS {
  color: #00afaa;
  font-size: 2.5rem;
}

.styles_number2__3uE_a {
  color: #425cc7;
  font-size: 2.5rem;
}

.styles_stat_name__2gqbJ {
  color: #797d93;
  font-size: 1rem;
}

.styles_book_open__1n9dF {
  position: absolute;
  top: 1.4rem;
  left: 1.2rem;
  width: 3rem;
}

.styles_rectagle2__3ZB-F {
  position: absolute;
  width: 5.6rem;
  height: 5.6rem;
  left: 9.4rem;
  top: 12.7rem;
  z-index: 2;
}

.styles_book__2IV6e {
  position: absolute;
  left: 10.5rem;
  top: 14rem;
  width: 3rem;
  z-index: 3;
}

.styles_user_check__2rANk {
  position: absolute;
  left: 1.5rem;
  top: 23rem;
  width: 3rem;
  z-index: 3;
}

.styles_video__10l69 {
  position: absolute;
  left: 11.3rem;
  top: 32.5rem;
  width: 2.5rem;
  z-index: 3;
}

.styles_rectagle4__Udysc {
  display: block;
  position: absolute;
  left: 9.5rem;
  top: 31rem;
  width: 5.5rem;
  height: 5.5rem;
  z-index: 1;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_container__2jcgm {
    display: block;
    background-color: #ffff;
  }

  .styles_download_tile__Xp0JU {
    width: 80%;
  }

  .styles_store_icon__3Nq2b {
    width: 7rem;
    margin-right: 0.5rem;
    object-fit: contain;
  } 

  .styles_app_icon__CcScv {
    width: 9.5rem;
    margin-right: -0.5rem;
    object-fit: contain;
  } 

  .styles_flex1__SUQ3X {
    font-size: 1.5rem;
    font-weight: bold;
    width: 70%;
    text-align: center;
    margin: auto;
  }
  .styles_app_view__2Nzs7 {
    width: 80%;
    margin-top: 5rem;
  }
  .styles_rectagle1__1yQpE {
    position: absolute;
    left: 0;
    width: 4rem;
    height: 3.5rem;
  }

  .styles_stats_container__1wZFF {
    height: 25rem;
  }

  .styles_img1__2wfv9 {
    width: 11rem;
    height: 17rem;
    top: 3.5rem;
  }

  .styles_img2__BYp_D {
    width: 11rem;
    top: 16.5rem;
  }

  .styles_rectagle3__2l2H6 {
    display: block;
    position: absolute;
    top: 16.3rem;
    width: 4.2rem;
    height: 4.2rem;
    z-index: 1;
  }

  .styles_user_check__2rANk {
    left: 1.2rem;
    top: 17.4rem;
    width: 2rem;
    z-index: 3;
  }

  .styles_stat1__dgQ7x {
    left: 5rem;
    top: 0rem;
    font-size: 1rem;
  }

  .styles_stat2__3K96I {
    left: 12rem;
    top: 9rem;
    font-size: 1rem;
  }

  .styles_stat3__20Dg8 {
    left: 5rem;
    top: 16.5rem;
    font-size: 1rem;
  }
  .styles_stat4__2pgIE {
    left: 12rem;
    top: 23.5rem;
    font-size: 1rem;
  }

  .styles_number1__AJRNS {
    font-size: 1.5rem;
  }

  .styles_number2__3uE_a {
    font-size: 1.5rem;
  }

  .styles_stat_name__2gqbJ {
    font-size: 0.8rem;
  }

  .styles_wrapp__2KVqZ {
    white-space: nowrap;
  }

  .styles_video__10l69 {
    left: 8.3rem;
    top: 24rem;
    width: 2rem;
    z-index: 2;
  }

  .styles_book__2IV6e {
    left: 8rem;
    top: 10rem;
    width: 2rem;
    z-index: 3;
  }

  .styles_book_open__1n9dF {
    top: 1rem;
    left: 1rem;
    width: 2rem;
    z-index: 3;
  }
.styles_rectagle2__3ZB-F {
  position: absolute;
  width: 4.2rem;
  height: 4.3rem;
  z-index: 1;
  left: 6.8rem;
  top: 9.1rem;
}
  .styles_rectagle4__Udysc {
    left: 7rem;
    top: 23rem;
    width: 4.2rem;
    height: 4.2rem;
    z-index: 1;
  }
}

.styles_links_frame__2cVEI {
  width: 40%;
  background-color: #a83eee;
  padding: 0.5rem;
  margin: auto;
  border-radius: 0.5rem;
  display: flex;
}

.styles_container__D5BQR {
  margin: 3rem 0;
}

.styles_social_logo__3qNN- {
  width: 3rem;
  margin: 0.3rem;
}

.styles_inner_frame__33MCf {
  margin: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_links_frame__2cVEI {
    width: 75%;
  }
  .styles_social_logo__3qNN- {
    width: 2.5rem;
  }
}

.styles_container__2Fdau {
  display: flex;
  padding: 8rem 0;
  background-color: #fbf4ff;
  position: relative;
  overflow: hidden;
  margin-top: 6rem;
}

.styles_flex1__1HrXQ {
  width: 50%;
  z-index: 2;
}
.styles_flex2__yb6oG {
  /* width: 50%; */
}
.styles_flex1_title__3kTo8 {
  font-size: 2rem;
  font-family: "poppinsSemiBold";
  width: 50%;
  margin-left: 3rem;
}

.styles_tutor_msg__30Bnv {
  text-align: left;
  width: 80%;
}
.styles_tutor_img__P5O59 {
  width: 50%;
  float: left;
  border-radius: 1rem;
}
.styles_tutor_info__3nXwb {
  display: flex;
  margin-top: 1rem;
  text-align: left;
}

.styles_rec1__1OeIM {
  position: absolute;
  right: 0;
  top: 0;
  width: 7rem;
  height: 9rem;
}
.styles_rec2__1Oo1j {
  position: absolute;
  right: 0;
  top: 5.5rem;
  width: 7rem;
}

.styles_rec3__3xqGM {
  position: absolute;
  left: 0;
  width: 6rem;
  height: 8rem;
  bottom: 4.5rem;
}
.styles_rec4__hntDe {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 7rem;
}

.styles_slider__3X6ym {
  height: 4rem;
  padding: 1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_container__2Fdau {
    display: block;
    padding: 4rem 0;
    margin-top: 0 !important;
  }

  .styles_flex1__1HrXQ {
    width: 100%;
  }
  .styles_flex2__yb6oG {
    width: 100%;
    margin-top: 2rem;
  }

  .styles_tutor_msg__30Bnv {
    margin: auto;
    width: 80%;
    text-align: left;
    font-size: 0.9rem;
  }

  .styles_flex1_title__3kTo8 {
    font-size: 1.5rem;
    width: 80%;
    margin: auto;
    text-align: left;
  }

  .styles_tutor_info__3nXwb {
    width: 80%;
    margin: 1rem auto;
  }

  .styles_rec1__1OeIM {
    width: 5rem;
    height: 7rem;
  }
  .styles_rec2__1Oo1j {
    top: 4.5rem;
    width: 5rem;
  }
  .styles_rec3__3xqGM {
    width: 4rem;
    height: 5rem;
    bottom: 3.5rem;
  }
  .styles_rec4__hntDe {
    width: 5rem;
  }
}

.styles_feature__1Z1Jx {
  display: flex;
  margin: 2rem 0;
}
.styles_feature_img__3YE7R {
  width: 90%;
  border: 1px solid #9501f6;
  padding: 0.3rem;
  border-radius: 0.5rem;
  /* float: left; */
}

.styles_title__1Vscg {
  font-size: 2rem;
  margin: 3rem 0 5rem 0;
  font-family: "poppinsBold";
}

.styles_feature_flex2__1CBOQ {
  text-align: left;
  margin-left: 1rem;
  background-color: #ffff;
  z-index: 2;
  width: 50%;
}

.styles_feature_title__1PYah {
  font-size: 1.5rem;
  margin: 1rem;
  font-family: "poppinsBold";
}

.styles_feature_description__3uDMT {
  margin: 1rem;
  color: #797d93;
  font-family: "poppinsRegular";
}

.styles_container__1Ab7I {
  width: 75%;
  margin: -2rem auto 3rem auto;
}
.styles_outer_container__35W4a {
  position: relative;
}
.styles_fall_backs__3Z16Q {
  width: 10rem;
}

.styles_mobile_view_top_img__1-lUF {
  position: absolute;
  right: 0;
  top: 0rem;
}

.styles_img11__1kuxo {
  position: absolute;
  width: 8rem;
  right: 0;
  top: 1rem;
}

.styles_img12__2C_ZO {
  position: absolute;
  right: 3.8rem;
  top: 8.2rem;
  width: 10rem;
}

.styles_feature_flex1__2ODsf {
  width: 50%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_feature__1Z1Jx {
    display: block;
    margin: 1rem 2rem;
  }
  .styles_container__1Ab7I {
    width: 100%;
    margin: 0rem auto;
  }
  .styles_feature_img__3YE7R {
    margin-bottom: 1rem;
    width: 100%;
  }
  .styles_title__1Vscg {
    font-size: 1.5rem;
    margin: 1rem 0 3rem 0;
  }

  .styles_feature_title__1PYah {
    margin: 0;
    font-size: 1.3rem;
  }

  .styles_feature_description__3uDMT {
    font-size: 0.9rem;
    color: #797d93;
    margin-top: 1rem !important;
    font-family: "poppinsRegular";
  }

  .styles_mobile_view_top_img__1-lUF {
    display: block;
    background-color: #f9f9f9;
    height: 4rem;
    margin-bottom: 2rem;
  }

  .styles_feature_flex1__2ODsf {
    width: 100%;
  }

  .styles_feature_flex2__1CBOQ {
    margin-left: 0;
    width: 100%;
  }

  .styles_img11__1kuxo {
    float: right;
    width: 6rem;

    z-index: -1;
  }
  .styles_feature_description__3uDMT {
    margin: 0;
  }
}

.styles_container__2UV-f {
  height: 2.5rem;
}

.styles_right_img__1-Dt3 {
  width: 30%;
  height: 100%;
  object-fit: cover;
  float: right;
}

.styles_left_img__3FiMJ {
  width: 30%;
  height: 100%;
  object-fit: cover;
  float: left;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_container__2UV-f {
    height: 2rem;
    background-color: #f9f9f9;
  }

  .styles_right_img__1-Dt3 {
    width: 40%;
    height: 100%;
  }

  .styles_left_img__3FiMJ {
    width: 40%;
    height: 100%;
  }
}

.styles_ytb_container__24FMo {
  /* display: flex; */
  margin: 0 3rem;
  z-index: 5;
}

.styles_header__6jwjY {
  font-family: "poppinsBold";
  font-size: 2rem;
  text-align: left;
  margin: 3rem;
}

.styles_container__2np4N {
  margin: 2rem 0;
  position: relative;
}

.styles_top_img__3fu58 {
  width: 20rem;
  position: absolute;
  right: 0;
  bottom: 7rem;
  z-index: -1;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_header__6jwjY {
    font-size: 1.5rem;
    margin: 1rem;
    text-align: center;
  }

  .styles_container__2np4N {
    margin: 3rem 0;
  }

  .styles_ytb_container__24FMo {
    display: flex;
  }

  .styles_top_img__3fu58 {
    width: 13rem;
    bottom: 10rem;
  }
  .styles_closeModal__XpiqZ {
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    margin: 0.5rem;
    cursor: pointer;
  }

  .styles_popup_container__2Ijpn {
    padding: 1.3rem !important;
  }
}

.styles_popup_container__2Ijpn {
  background-color: #fff;
  padding: 1.5rem;
  position: relative;
  border-radius: 0.5rem;
}

.styles_closeModal__XpiqZ {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
}

:root {
  --primary:#000A45;
  --fontcolor:#3E4365;
  --transition-style:all 0.3s ease-in-out;
  --font-primary: "Poppins", sans-serif;
}

h1 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h2 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h3 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h4 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h5 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

h6 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  line-height: normal;
  color: #000;
  color: #000629;
}

:root * {
  scrollbar-width: thin;
}
:root *::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.625rem;
}
:root *::-webkit-scrollbar-track {
  background: #f7f7f7;
}
:root *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

* {
  margin: 0;
  padding: 0;
  border: medium none;
  outline: medium none !important;
  list-style: outside none none;
  font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
  outline: none !important;
}

html, body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  color: #000629;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
}

a img {
  border: none;
}

a, a:hover, a:active, a:focus, button, .btn, .close {
  transition: all 0.3s ease-in-out;
  transition: var(--transition-style);
  outline: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

dl, ol, ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

::selection {
  background-color: #000A45;
  background-color: var(--primary);
  color: #fff;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}

/*! ---placeholder and form-control--- !*/
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  background-color: #fff;
  border: 0.063rem solid #888;
  color: #000;
  font-size: 1rem;
  box-shadow: none;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition: var(--transition-style);
  border-radius: 1.563rem;
  height: 2.5rem;
  padding: 0.5rem 1.25rem;
  text-align: left;
  text-overflow: ellipsis;
}
.form-control::-webkit-input-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition: var(--transition-style);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus::-webkit-input-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control::-moz-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: var(--transition-style);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus::-moz-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:-moz-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: var(--transition-style);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus:-moz-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:-ms-input-placeholder {
  color: #888;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-transition: var(--transition-style);
  transition: var(--transition-style);
  text-align: left;
}
.form-control:focus:-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.3);
}
.form-control:focus {
  background-color: #fff;
  color: #000;
  border-color: #888;
}
@media (max-width: 991px) {
  .form-control {
    height: 2.813rem;
  }
}

textarea.form-control {
  height: 100px;
  resize: none;
}

.form-group {
  margin-bottom: 1.375rem;
}
.form-group label {
  color: #000;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-group .error {
  margin: 0.313rem 0 0;
  color: #f00;
}

/*! ---placeholder and form-control--- !*/
span {
  position: relative;
}

p {
  font-size: 1rem;
  line-height: 1.8rem;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-primary);
  font-weight: 400;
  color: #3E4365;
}
@media (max-width: 767px) {
  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

p + p {
  margin-top: 1rem;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
}
@media (max-width: 1400px) {
  h2 {
    font-size: 2.75rem;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 2.188rem;
  }
}

h3 {
  font-size: 1.875rem;
  font-weight: 700;
}
@media (max-width: 767px) {
  h3 {
    font-size: 1.75rem;
  }
}

.navbar-toggler {
  height: 1.875rem;
  padding: 0;
  border: none;
}
.navbar-toggler .navbar-toggler-icon {
  width: 2.188rem;
  display: inline-block;
  height: 0.25rem;
  position: relative;
  border-radius: 0.313rem;
  background: none;
}
.navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
  width: 2.188rem;
  transition: all 0.3s ease-in-out;
  transition: var(--transition-style);
  height: 0.25rem;
  background: #000A45;
  background: var(--primary);
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  border-radius: 0.313rem;
  top: -0.813rem;
  margin-top: 0.625rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.navbar-toggler .navbar-toggler-icon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.navbar-toggler.collapsed .navbar-toggler-icon {
  background: #000A45;
  background: var(--primary);
}
.navbar-toggler.collapsed .navbar-toggler-icon:after {
  top: -1.313rem;
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.navbar-toggler.collapsed .navbar-toggler-icon:before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  top: 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1260px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1360px;
  }
}
/* ---------------------------- Menu section ---------------------------- */
.navbar {
  background: white;
  padding: 0.875rem 0;
}
.navbar .navbar-collapse {
  justify-content: flex-end;
}
.navbar .navbar-collapse .navbar-nav li {
  padding: 0 0.938rem;
}
.navbar .navbar-collapse .navbar-nav li a {
  color: #000A45;
  padding: 0 !important;
  font-size: 0.938rem;
}
.navbar .navbar-collapse .navbar-nav li a:hover, .navbar .navbar-collapse .navbar-nav li a.active {
  color: #9501F6;
}
.navbar .navbar-collapse .navbar-nav li:first-child {
  padding-left: 0;
}
.navbar .navbar-collapse .navbar-nav li:last-child {
  padding-right: 0;
}
.navbar .navbar-collapse .searchBtn {
  width: 2.188rem;
  height: 2.625rem;
  border: 0.063rem solid #000A45;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #3E4365;
  font-size: 0.75rem;
  margin-left: 2.188rem;
}
.navbar .navbar-collapse .login-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.625rem;
  width: 130px;
  padding: 0.313rem;
  margin-left: 1rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 1rem;
  background: #000A45;
  border-radius: 0.5rem;
}
.navbar .navbar-collapse .inputSearchBg {
  position: absolute;
  z-index: 2;
  top: 100%;
  padding: 0 0.625rem;
  background: white;
  border-radius: 0 0 0.625rem 0.625rem;
  transition: all 0.3s ease-in-out;
  transition: var(--transition-style);
  margin-top: -0.5rem;
  height: 0;
  overflow: hidden;
}
.navbar .navbar-collapse .inputSearchBg.active {
  height: 3.75rem;
  margin-top: 0;
  padding: 0.625rem;
}
@media (max-width: 991px) {
  .navbar .navbar-collapse .navbar-nav li {
    padding: 0 0.625rem;
  }
  .navbar .navbar-collapse .searchBtn {
    margin-left: 1.5rem;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-collapse .navbar-nav {
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
  .navbar .navbar-collapse .navbar-nav li {
    padding: 0.5rem 0;
    text-align: left;
  }
  .navbar .navbar-collapse .searchBtn {
    margin-left: 0;
  }
}

/* ---------------------------- Menu section ---------------------------- */
/* ---------------------------- Baner section ---------------------------- */
.abtBnr {
  height: 40rem;
  background-image: linear-gradient(150deg, #000a45 0%, #9501f7 70%);
  position: relative;
}
.abtBnr .bnrBg {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  pointer-events: none;
}
.abtBnr .bnrBg.lft {
  left: 7%;
  opacity: 0.1;
}
.abtBnr .bnrBg.rgt {
  right: 0;
}
.abtBnr .bnrBg.main {
  right: 3%;
  height: 75%;
  bottom: 0;
  margin: auto;
}
.abtBnr .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
}
.abtBnr__box * {
  color: white;
}
.abtBnr__box h1 {
  font-weight: 700;
  font-size: 3.5rem;
}
.abtBnr__box p {
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  .abtBnr .bnrBg.lft {
    left: 0;
  }
  .abtBnr .bnrBg.rgt {
    opacity: 0.3;
  }
}
@media (max-width: 991px) {
  .abtBnr .container {
    justify-content: center;
  }
  .abtBnr .abtBnr__box {
    text-align: center;
  }
  .abtBnr .abtBnr__box h1 {
    font-size: 3.75rem;
  }
  .abtBnr .bnrBg.main {
    right: 0;
    left: 0;
  }
}
@media (max-width: 767px) {
  .abtBnr {
    overflow: hidden;
  }
  .abtBnr .abtBnr__box h1 {
    font-size: 2rem;
  }
}
@media (max-width: 576px) {
  .abtBnr {
    height: 25rem;
  }
}

/* ---------------------------- Baner section ---------------------------- */
/* ---------------------------- About text section ---------------------------- */
.abtText {
  text-align: center;
  padding: 145px 0;
}
.abtText h2 {
  margin-bottom: 3rem;
}
.abtText p {
  max-width: 1030px;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 991px) {
  .abtText {
    padding: 5rem 0 2rem;
  }
  .abtText h2 {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 767px) {
  .abtText h2 {
    margin-bottom: 1.25rem;
    font-size: 1.75rem;
  }
}

/* ---------------------------- About text section ---------------------------- */
/* ---------------------------- Value box section ---------------------------- */
.valuesBg {
  text-align: center;
  padding-top: 150px;
}
.valuesBg h3 {
  margin-bottom: 4.063rem;
}
.valuesBg__Inbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 920px;
  height: 460px;
  margin: 0 auto;
}
.valuesBg__Inbox__row {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.valuesBg__Inbox__row .box {
  width: 100%;
  max-width: 283px;
  height: 212px;
  background: white;
  padding: 2rem 0.313rem;
  border-radius: 1.5rem;
  border: 0.063rem solid #9501F6;
  box-shadow: 0.875rem 0.875rem 2.438rem rgba(96, 6, 177, 0.14);
}
.valuesBg__Inbox__row .box h6 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #3E4365;
  margin-top: 1.563rem;
}
.valuesBg__Inbox__row .box.colred {
  background: #FBF4FF;
  opacity: 0.6;
  border: none;
  box-shadow: none;
}
.valuesBg__Inbox__row .box.colred.second {
  position: relative;
  z-index: 0;
}
@media (max-width: 1400px) {
  .valuesBg__Inbox__row .box h6 {
    font-size: 1rem;
  }
}
@media (max-width: 991px) {
  .valuesBg__Inbox__row .box {
    position: relative;
    z-index: 2;
  }
  .valuesBg__Inbox__row .box.colred {
    display: none;
  }
}
@media (max-width: 767px) {
  .valuesBg__Inbox__row .box {
    max-width: 240px;
  }
  .valuesBg__Inbox__row .box h6 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
.valuesBg__Inbox__row .bgImg {
  position: absolute;
}
.valuesBg__Inbox__row .bgImg.row1 {
  right: 5.125rem;
  bottom: -2.25rem;
}
.valuesBg__Inbox__row .bgImg.row2 {
  left: 5.125rem;
  top: -2.25rem;
  z-index: 1;
}
@media (max-width: 991px) {
  .valuesBg__Inbox__row .bgImg {
    z-index: 0;
  }
  .valuesBg__Inbox__row .bgImg.row1 {
    right: 5.625rem;
  }
  .valuesBg__Inbox__row .bgImg.row2 {
    left: 5.625rem;
  }
}
@media (max-width: 991px) {
  .valuesBg__Inbox {
    max-width: 610px;
  }
}
@media (max-width: 540px) {
  .valuesBg__Inbox {
    height: auto;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row {
    flex-direction: column;
    align-items: center;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row .box {
    max-width: 90%;
    margin-bottom: 1.25rem;
  }
  .valuesBg__Inbox .valuesBg__Inbox__row .box:last-child {
    margin-bottom: 0;
  }
}
.valuesBg .textBox {
  max-width: 1030px;
  margin: 0 auto;
  padding: 100px 0 150px;
}
@media (max-width: 991px) {
  .valuesBg {
    padding-top: 5rem;
  }
  .valuesBg .textBox {
    padding: 5rem 0;
  }
}
@media (max-width: 767px) {
  .valuesBg h3 {
    margin-bottom: 3.125rem;
  }
}

/* ---------------------------- Value box section ---------------------------- */
/* ---------------------------- Our vision section ---------------------------- */
.ourVisionSec {
  text-align: center;
  background: #FBF4FF;
  padding-top: 150px;
}
.ourVisionSec .logoBig {
  max-width: 1030px;
  margin-bottom: 150px;
}
@media (max-width: 1200px) {
  .ourVisionSec .logoBig {
    max-width: 800px;
  }
}
@media (max-width: 991px) {
  .ourVisionSec .logoBig {
    max-width: 75%;
  }
}
@media (max-width: 767px) {
  .ourVisionSec .logoBig {
    max-width: 70%;
    margin-bottom: 6.25rem;
  }
}
.ourVisionSec .row {
  margin: 0;
}
.ourVisionSec .row [class*=col] {
  padding: 0;
}
.ourVisionSec .visionRow .imgBox {
  text-align: left;
}
.ourVisionSec .visionRow .contBox .box {
  max-width: 580px;
}
@media (max-width: 576px) {
  .ourVisionSec .visionRow .contBox .box {
    padding-left: 0;
  }
}
.ourVisionSec .missionRow .imgBox {
  text-align: right;
}
.ourVisionSec .missionRow .contBox {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.ourVisionSec .missionRow .contBox .box {
  max-width: 415px;
}
@media (max-width: 576px) {
  .ourVisionSec .missionRow .contBox {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .ourVisionSec .missionRow .contBox .box {
    padding-right: 0;
  }
  .ourVisionSec .missionRow .contBox .imgBox {
    display: flex;
    align-items: flex-end;
  }
}
.ourVisionSec .contBox {
  text-align: left;
}
.ourVisionSec .contBox .box {
  padding: 0 1.25rem;
}
.ourVisionSec .contBox h2 {
  margin-bottom: 2.813rem;
}
@media (max-width: 991px) {
  .ourVisionSec {
    padding-top: 5rem;
  }
  .ourVisionSec .contBox h2 {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 991px) {
  .ourVisionSec .contBox h2 {
    font-size: 1.375rem;
    margin-bottom: 0.875rem;
  }
}

/* ---------------------------- Our vision section ---------------------------- */
/* ---------------------------- How it started section ---------------------------- */
.howStartedSec {
  padding: 250px 0 150px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.howStartedSec .bgImg {
  position: absolute;
  top: 0;
  z-index: 0;
  bottom: 0;
  margin: auto;
}
.howStartedSec .bgImg.left {
  left: 0;
}
.howStartedSec .bgImg.right {
  right: 0;
}
@media (min-width: 1300px) {
  .howStartedSec .bgImg {
    height: 100%;
    max-height: 1100px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .bgImg.left {
    left: -20%;
  }
  .howStartedSec .bgImg.right {
    right: -20%;
  }
}
@media (max-width: 767px) {
  .howStartedSec .bgImg {
    display: none;
  }
}
.howStartedSec .textBox {
  position: relative;
  z-index: 1;
  max-width: 1030px;
  margin: 2rem auto 2.5rem;
  text-align: left;
}
.howStartedSec .textBoxBg {
  max-width: 825px;
  margin: 0 auto;
}
.howStartedSec .textBoxBg .textBox {
  margin: 2.5rem 0 0;
}
@media (max-width: 991px) {
  .howStartedSec .textBoxBg {
    padding: 0 0.938rem;
  }
}
.howStartedSec .founderSec {
  position: relative;
  /*padding:120px 0 150px;*/
}
.howStartedSec .founderSec__box {
  max-width: 825px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.howStartedSec .founderSec__box .box .desBox {
  padding: 1.563rem 0;
}
.howStartedSec .founderSec__box .box .desBox h6 {
  font-size: 1.5rem;
  font-weight: 700;
}
.howStartedSec .founderSec__box .box .desBox div {
  font-size: 1.25rem;
  font-weight: 500;
  color: #3E4365;
}
@media (max-width: 767px) {
  .howStartedSec .founderSec__box .box .desBox h6 {
    font-size: 0.875rem;
  }
  .howStartedSec .founderSec__box .box .desBox div {
    font-size: 0.625rem;
  }
}
@media (max-width: 767px) {
  .howStartedSec .founderSec__box .box {
    max-width: 45%;
  }
  .howStartedSec .founderSec__box .box img {
    max-height: 375px;
  }
}
@media (max-width: 576px) {
  .howStartedSec .founderSec__box .box img {
    max-height: 300px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .founderSec__box {
    padding: 0 0.938rem;
  }
}
@media (max-width: 420px) {
  .howStartedSec .founderSec__box {
    flex-direction: column;
    align-items: center;
  }
  .howStartedSec .founderSec__box .box {
    max-width: 100%;
  }
  .howStartedSec .founderSec__box .box img {
    max-height: 375px;
  }
}
@media (max-width: 991px) {
  .howStartedSec .founderSec {
    padding-bottom: 0;
  }
}
@media (max-width: 991px) {
  .howStartedSec {
    padding: 5rem 0;
    padding-top: 4.375rem;
  }
  .howStartedSec .founderSec {
    padding-top: 5rem;
  }
}
@media (max-width: 767px) {
  .howStartedSec .founderSec {
    padding-top: 0;
  }
}

/* ---------------------------- How it started section ---------------------------- */
/* ---------------------------- Footer Social section ---------------------------- */
.footerSocial {
  text-align: center;
  padding-bottom: 120px;
}
.footerSocial__Box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: 130px;
  background: #A83EEE;
  border-radius: 1.5rem;
}
.footerSocial ul {
  display: flex;
}
.footerSocial ul li {
  margin-right: 0.938rem;
}
.footerSocial ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: white;
  border-radius: 50%;
  border: 0.063rem solid #9501F6;
  color: #000A45;
  font-size: 1.25rem;
}
.footerSocial ul li a:hover {
  color: #9501F6;
}
.footerSocial ul li:last-child {
  margin-right: 0;
}
@media (max-width: 991px) {
  .footerSocial {
    padding-bottom: 5rem;
  }
  .footerSocial .footerSocial__Box {
    height: 6.25rem;
    max-width: 400px;
  }
}

/* ---------------------------- Footer Social section ---------------------------- */
/* ---------------------------- Footer section ---------------------------- */
.footer {
  padding: 5.625rem 0;
  background: #000A45;
}
.footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .container ul {
  display: flex;
}
.footer .container li {
  position: relative;
  padding: 0 2rem;
}
.footer .container li a {
  color: white;
  font-size: 1.125rem;
}
.footer .container li a:hover {
  color: #8102DF;
}
@media (max-width: 767px) {
  .footer .container li a {
    font-size: 0.875rem;
  }
}
.footer .container li:after {
  content: "";
  background: #8102DF;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  position: absolute;
  right: -1rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.footer .container li:first-child {
  padding-left: 0;
}
.footer .container li:last-child {
  padding-right: 0;
}
.footer .container li:last-child:after {
  display: none;
}
@media (max-width: 1200px) {
  .footer .container {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .footer .container ul {
    margin-top: 0.875rem;
  }
}
@media (max-width: 1400px) {
  .footer {
    padding: 2rem 0;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 1.5rem 0;
  }
  .footer img {
    max-width: 150px;
  }
}
@media (max-width: 991px) {
  .footer .container ul {
    flex-direction: column;
  }
  .footer .container ul li {
    padding: 0.5rem 0.75rem;
  }
  .footer .container ul li:first-child {
    padding-left: 0.75rem;
  }
  .footer .container ul li:last-child:after {
    display: inline-block;
  }
  .footer .container ul li:after {
    width: 0.5rem;
    height: 0.5rem;
    left: -0.25rem;
    right: auto;
  }
}

/* ---------------------------- Footer section ---------------------------- */

/*# sourceMappingURL=mainStyle.css.map */

.styles_map_frame__3QKJQ {
  width: 94%;
  height: 25rem;
  /* padding: 0.5rem; */
  margin: auto;
  border-radius: 0.5rem;
  display: flex;
  border-radius: 1.5rem;
  position: relative;
}

iframe {
  border: 1px solid gainsboro;
}

.styles_container__2zW9a {
  margin: 1.5rem 0;
}

.styles_map_info__1T8Q4 {
  position: absolute;
  left: 9rem;
  bottom: 1.25rem;
  /* height: 6rem; */
  border: 1px solid #e5eaff;
  border-radius: 0.8rem;
  width: 14rem;
  /* margin: 14rem auto; */
  background-color: white;
  padding: 1.5rem;
}

.styles_adress_icon__HJ2WQ {
  width: 1.2rem;
}

.styles_adress_ui__103wu {
  margin-right: 0.4rem;
  bottom: 30px;
  right: 20px;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  background: #9501f6;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.styles_main_adress__3bCi1 {
  font-family: "poppinsRegular";
  display: -webkit-box;
  font-size: 0.9rem;
}

.styles_address_name__3Pw4W {
  color: #000629;
  font-size: 0.7rem;
  font-family: 'poppinsSemiBold' !important;
  margin-left: 2.4rem;
  margin-top: 0.6rem;
  text-align: left;
}

.styles_poppinsText__3eDqV {
  font-family: "poppinsSmall";
}

@media (max-width: 767px) {
  .styles_map_info__1T8Q4 {
    left: 2.6rem;
    width: 15rem;
    padding: 1rem !important;
  }

  .styles_main_adress__3bCi1 {
    font-family: "poppinsRegular" !important;
    display: -webkit-box;
    font-size: 0.9rem;
  }

  .styles_adress_icon__HJ2WQ {
    width: 1.1rem;
  }

  .styles_adress_ui__103wu {
    margin-right: 0.4rem;
    bottom: 30px;
    right: 20px;
    font-size: 0rem;
    width: 2rem;
    height: 2rem;
    background: #9501f6;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    z-index: 10;
  }
}
.styles_container__7SNnI {
  position: relative;
  display: flex;
  /* margin-top: 6rem; */
  margin-top: 2rem;
}

.styles_flex1__3vEho {
  font-size: 2rem;
  font-family: "poppinsBold";
  width: 50%;
  text-align: left;
  text-align: initial;
  margin-left: 7rem;
  margin-top: 5rem;
  margin-right: 3rem;
}

.styles_stats_container__3aZXr {
  position: relative;
}

.styles_stat1__xHBT1 {
  /* text-align: left; */
  position: absolute;
  /* left: 8rem; */
  top: 0.5rem;
  font-size: 1.5rem;
  font-family: "poppinsSmall";
}

.styles_stat2__22qe- {
  position: absolute;
  text-align: left;
  top: 12.5rem;
  font-size: 1.5rem;
}

.styles_stat3__3EW1g {
  position: absolute;
  text-align: left;
  top: 12.5rem;
  font-size: 1.5rem;
}


.styles_number2__TPeB5 {
  font-family: "poppinsRegular" !important;
  color: #000629;
  font-size: 1.2rem;
}

.styles_stat_name__bxNMP {
  color: #3e4365;
  font-size: 1.4rem;
  padding-right: 9rem;
  font-family: "poppinsRegular";
}

.styles_address_name__1yFi0 {
  color: #000629;
  font-size: 1.5rem;
  font-family: 'poppinsSemiBold' !important;
  padding-right: 9rem;
  margin-left: 3rem;
}

.styles_phone__14END {
  width: 1.4rem;
}

.styles_email_icon__39uy3 {
  width: 1.4rem;
}

.styles_phone_ui__3TbMb {
  margin-right: 0.4rem;
  bottom: 30px;
  right: 20px;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  background: #9501f6;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.styles_img1__tJdzD {
  position: absolute;
  left: 5rem;
  width: 18rem;
  height: 25rem;
  top: -2rem;
}

.styles_img2__1sM6j {
  position: absolute;
  left: 5rem;
  width: 18rem;
  top: 17rem;
  opacity: 0.3;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_container__7SNnI {
    display: block;
    background-color: #ffff;
  }

  .styles_address_name__1yFi0 {
    color: #000629;
    font-size: 1.2rem;
    font-family: 'poppinsSemiBold' !important;
    padding-right: 0rem !important;
  }

  .styles_flex1__3vEho {
    font-size: 1.5rem;
    font-weight: bold;
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .styles_stats_container__3aZXr {
    height: 16rem;
  }

  .styles_stat1__xHBT1 {
    left: 0rem;
    top: 0rem;
    font-size: 1rem;
  }

  .styles_stat2__22qe- {
    left: 1rem;
    top: 8rem;
    font-size: 1rem;
  }

  .styles_stat3__3EW1g {
    left: 1rem;
    top: 7rem;
    font-size: 1rem;
  }

  .styles_number2__TPeB5 {
    font-family: "poppinsRegular" !important;
    color: #000629;
    font-size: 1.1rem;
  }

  .styles_stat_name__bxNMP {
    color: #3e4365;
    font-size: 1rem;
    padding-right: 0rem;
    font-family: "poppinsRegular";
  }

  .styles_contact_name__1_-8f {
    font-size: 1.5rem;
    text-align: inherit;
    font-family: "poppinsBold";
  }

  .styles_div_component__B30oU {
    padding: 0rem !important;
    padding-left: 1rem !important; 
    padding-right: 0rem !important;
    width: 93% !important;
    /* margin-top: 2rem; */
  }

  .styles_form__121L6 {
    background-color: #edd2fe;
    border: 1px solid #edd2fe;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 1rem !important;
  }

  .styles_img1__tJdzD {
    left: 2rem;
    width: 10rem;
    top: -2rem;
    height: 16rem;
  }

  .styles_img2__1sM6j {
    /* display: none; */
    position: absolute;
    left: 2rem;
    width: 10rem;
    top: 10rem;
    opacity: 0.3;
  }
}

.styles_div_component__B30oU {
padding: 1rem;
padding-right: 5rem;
width: 50%;
}

form {
  background-color: #edd2fe;
  border: 1px solid #edd2fe;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 80px;
}

.styles_input__1eJtU {
  border: 1px solid #000a45;
  border-radius: 0.8rem;
  font-family: "poppinsRegular";
  color: #3c3c3c;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 3.3rem;
}

.styles_message_input__eTcLv {
  border: 1px solid #000a45;
  border-radius: 0.8rem;
  font-family: "poppinsRegular";
  color: #3c3c3c;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 6.3rem;
}

.styles_form_margin__po9hg {
  margin-top: 2rem;
}

.styles_label__3xte5 {
  text-align: left;
  color: #3d3d3d;
  display: block;
  font-family: "poppinsMedium";
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.styles_button__ESg1E {
  display: flex;
  background-color: #000a45;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffff;
  font-size: 14px;
  font-family: "poppinsBold";
  place-content: center;
  width: 9rem;
  margin-top: 2rem;
}

.styles_contact_name__1_-8f {
  font-size: 2.2rem;
  font-family: "poppinsBold";
}
