.map_frame {
  width: 94%;
  height: 25rem;
  /* padding: 0.5rem; */
  margin: auto;
  border-radius: 0.5rem;
  display: flex;
  border-radius: 1.5rem;
  position: relative;
}

iframe {
  border: 1px solid gainsboro;
}

.container {
  margin: 1.5rem 0;
}

.map_info {
  position: absolute;
  left: 9rem;
  bottom: 1.25rem;
  /* height: 6rem; */
  border: 1px solid #e5eaff;
  border-radius: 0.8rem;
  width: 14rem;
  /* margin: 14rem auto; */
  background-color: white;
  padding: 1.5rem;
}

.adress_icon {
  width: 1.2rem;
}

.adress_ui {
  margin-right: 0.4rem;
  bottom: 30px;
  right: 20px;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  background: #9501f6;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.main_adress {
  font-family: "poppinsRegular";
  display: -webkit-box;
  font-size: 0.9rem;
}

.address_name {
  color: #000629;
  font-size: 0.7rem;
  font-family: 'poppinsSemiBold' !important;
  margin-left: 2.4rem;
  margin-top: 0.6rem;
  text-align: left;
}

.poppinsText {
  font-family: "poppinsSmall";
}

@media (max-width: 767px) {
  .map_info {
    left: 2.6rem;
    width: 15rem;
    padding: 1rem !important;
  }

  .main_adress {
    font-family: "poppinsRegular" !important;
    display: -webkit-box;
    font-size: 0.9rem;
  }

  .adress_icon {
    width: 1.1rem;
  }

  .adress_ui {
    margin-right: 0.4rem;
    bottom: 30px;
    right: 20px;
    font-size: 0rem;
    width: 2rem;
    height: 2rem;
    background: #9501f6;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    z-index: 10;
  }
}