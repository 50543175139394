.ytb_container {
  /* display: flex; */
  margin: 0 3rem;
  z-index: 5;
}

.header {
  font-family: "poppinsBold";
  font-size: 2rem;
  text-align: left;
  margin: 3rem;
}

.container {
  margin: 2rem 0;
  position: relative;
}

.top_img {
  width: 20rem;
  position: absolute;
  right: 0;
  bottom: 7rem;
  z-index: -1;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .header {
    font-size: 1.5rem;
    margin: 1rem;
    text-align: center;
  }

  .container {
    margin: 3rem 0;
  }

  .ytb_container {
    display: flex;
  }

  .top_img {
    width: 13rem;
    bottom: 10rem;
  }
  .closeModal {
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    margin: 0.5rem;
    cursor: pointer;
  }

  .popup_container {
    padding: 1.3rem !important;
  }
}

.popup_container {
  background-color: #fff;
  padding: 1.5rem;
  position: relative;
  border-radius: 0.5rem;
}

.closeModal {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
}
