.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #000a45; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  background: #9501f7;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 10;
}

.back-to-top:hover {
  background: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .back-to-top {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .swiper_yt-pagination {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 45%;
    height: 1.8rem;
    border: 1px solid #a83eee;
  }

  .swiper_cl-pagination {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 30%;
    height: 1.8rem;
    border: 1px solid #a83eee;
  }

  .swiper {
    width: 100% !important;
  }

  .pagination_container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination_mobile {
    background-color: #f5e5ff !important;
    border-radius: 2rem;
    margin: auto;
    width: 30%;
    bottom: 2.5rem !important;
    justify-content: center;
    left: 35%;
    border: 1px solid #a83eee;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    margin: 0 0.2rem;
  }

  .pagination_container-yt {
    width: 100% !important;
    position: relative !important;
    left: 0 !important;
    margin-top: 1.5rem !important;
  }
}

.swiper {
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.swiper_yt {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper_cl {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  background: url("./assets/group_434.png") no-repeat center center;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  /* bottom: 10% !important;
  left: 2% !important; */
}

.pagination_container {
  width: 100%;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  margin: 0 0.2rem;
}

.swiper-slide_yt {
  width: 100%;
  /* display: flex; */
  /* padding: 2rem 0;
  justify-content: space-between; */
}

.db_cont {
  display: flex;
}
.swiper-slide {
  border-radius: 1rem;
  overflow: hidden;
}
.vieo_deck {
  margin-right: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  display: inline-flex;
}

.col_cont {
  display: block;
}

.up-arraow {
  width: 1.5rem;
}

.swiper-button-next {
  background-image: url(./assets/group_434.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
}

.swiper-button-prev {
  background-image: url(./assets/group_435.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next-yt {
  background-image: url(./assets/group_434.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
}

.swiper-button-prev-yt {
  background-image: url(./assets/group_435.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  position: absolute !important;
  left: 57% !important;
  top: 80% !important;
}

.swiper-button-prev {
  position: absolute !important;
  left: 50% !important;
  top: 80% !important;
}

.swiper-button-next-yt {
  position: absolute !important;
  left: 13% !important;
  bottom: -25% !important;
  height: 4rem;
  width: 1.7rem;
  cursor: pointer;
}

.swiper-button-prev-yt {
  position: absolute !important;
  left: 5% !important;
  bottom: -25% !important;
  height: 4rem;
  width: 1.7rem;
  cursor: pointer;
}

.pagination_container-yt {
  width: 10%;
  position: absolute;
  left: 5%;
  bottom: -17%;
}

.swiper-pagination-bullet-active {
  background: #000a45 !important;
}

.swiper-pagination {
  top: 76.5% !important;
  left: 53%;
}

.swiper-pagination_mobile {
  background-color: #f5e5ff !important;
  border-radius: 2rem;
  margin: auto;
  height: 1.8rem;
  width: 30%;
  bottom: 2.5rem !important;
  justify-content: center;
  left: 35%;
  border: 1px solid #a83eee;
}

.vieo_deck {
 cursor: pointer;
}

.up_arrow {
  position: fixed; 
  cursor: pointer;
  bottom: 40px; 
  right: 40px; 
  color: rgb(255, 255, 255); 
  background-color: #9501f7;
  z-index: 1; width: 45px; 
  text-align: center; 
  height: 45px; 
  border-radius: 50%; 
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px; 
  display: inline;
}

.up_arrow:hover {
  background: #9d3ddd;
}