.links_frame {
  width: 40%;
  background-color: #a83eee;
  padding: 0.5rem;
  margin: auto;
  border-radius: 0.5rem;
  display: flex;
}

.container {
  margin: 3rem 0;
}

.social_logo {
  width: 3rem;
  margin: 0.3rem;
}

.inner_frame {
  margin: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .links_frame {
    width: 75%;
  }
  .social_logo {
    width: 2.5rem;
  }
}
