.feature {
  display: flex;
  margin: 2rem 0;
}
.feature_img {
  width: 90%;
  border: 1px solid #9501f6;
  padding: 0.3rem;
  border-radius: 0.5rem;
  /* float: left; */
}

.title {
  font-size: 2rem;
  margin: 3rem 0 5rem 0;
  font-family: "poppinsBold";
}

.feature_flex2 {
  text-align: left;
  margin-left: 1rem;
  background-color: #ffff;
  z-index: 2;
  width: 50%;
}

.feature_title {
  font-size: 1.5rem;
  margin: 1rem;
  font-family: "poppinsBold";
}

.feature_description {
  margin: 1rem;
  color: #797d93;
  font-family: "poppinsRegular";
}

.container {
  width: 75%;
  margin: -2rem auto 3rem auto;
}
.outer_container {
  position: relative;
}
.fall_backs {
  width: 10rem;
}

.mobile_view_top_img {
  position: absolute;
  right: 0;
  top: 0rem;
}

.img11 {
  position: absolute;
  width: 8rem;
  right: 0;
  top: 1rem;
}

.img12 {
  position: absolute;
  right: 3.8rem;
  top: 8.2rem;
  width: 10rem;
}

.feature_flex1 {
  width: 50%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .feature {
    display: block;
    margin: 1rem 2rem;
  }
  .container {
    width: 100%;
    margin: 0rem auto;
  }
  .feature_img {
    margin-bottom: 1rem;
    width: 100%;
  }
  .title {
    font-size: 1.5rem;
    margin: 1rem 0 3rem 0;
  }

  .feature_title {
    margin: 0;
    font-size: 1.3rem;
  }

  .feature_description {
    font-size: 0.9rem;
    color: #797d93;
    margin-top: 1rem !important;
    font-family: "poppinsRegular";
  }

  .mobile_view_top_img {
    display: block;
    background-color: #f9f9f9;
    height: 4rem;
    margin-bottom: 2rem;
  }

  .feature_flex1 {
    width: 100%;
  }

  .feature_flex2 {
    margin-left: 0;
    width: 100%;
  }

  .img11 {
    float: right;
    width: 6rem;

    z-index: -1;
  }
  .feature_description {
    margin: 0;
  }
}
