.main_heading {
  font-size: 2rem;
  font-family: "poppinsBold";
  margin: 2rem 0;
}

.sub_heading {
  width: 40%;
  margin: auto;
}

.learning_list_container {
  display: flex;
  justify-content: space-between;
  margin: 3rem 1rem;
  flex-wrap: wrap;
  background-color: #ffff;
  z-index: 5;
}

.learning_item {
  width: 20%;
  /* height: 2.8rem; */
  border: 1px solid #425cc7;
  border-radius: 0.9rem;
  padding: 1rem;
  display: flex;
  margin: 0.5rem;
}

.learning_item_name {
  margin: auto 0 auto 1.5rem;
  text-align: left;
}

.learning_item_icon {
  width: 3.5rem;
  object-fit: contain;
}

.container {
  margin: 7rem 3rem;
}

.top_image {
  position: absolute;
  width: 16rem;
  right: 0;
  z-index: -1;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .learning_list_container {
    display: block;
  }

  .learning_item {
    width: 70%;
    height: 4rem;
    padding: 0.5rem;
    margin: 1rem auto;
  }

  .main_heading {
    font-size: 1.5rem;
  }

  .container {
    margin: 4rem 0;
  }

  .learning_item_icon {
    width: 3rem;
    object-fit: contain;
    margin-left: 0.5rem;
  }

  .learning_item_name {
    margin: auto 0 auto 2rem;
    text-align: left;
  }
}
