body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "poppinsRegular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "poppinsRegular";
  src: local("poppins"), url("./fonts/PoppinsRegular.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsBold";
  src: local("poppinsBold"), url("./fonts/PoppinsBold.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsMedium";
  src: local("poppinsMedium"),
    url("./fonts/PoppinsMedium.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsSemiBold";
  src: local("poppinsSemiBold"),
    url("./fonts/PoppinsSemiBold.ttf") format("truetype");
}
