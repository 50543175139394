.container {
  display: flex;
  padding: 8rem 0;
  background-color: #fbf4ff;
  position: relative;
  overflow: hidden;
  margin-top: 6rem;
}

.flex1 {
  width: 50%;
  z-index: 2;
}
.flex2 {
  /* width: 50%; */
}
.flex1_title {
  font-size: 2rem;
  font-family: "poppinsSemiBold";
  width: 50%;
  margin-left: 3rem;
}

.tutor_msg {
  text-align: left;
  width: 80%;
}
.tutor_img {
  width: 50%;
  float: left;
  border-radius: 1rem;
}
.tutor_info {
  display: flex;
  margin-top: 1rem;
  text-align: left;
}

.rec1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 7rem;
  height: 9rem;
}
.rec2 {
  position: absolute;
  right: 0;
  top: 5.5rem;
  width: 7rem;
}

.rec3 {
  position: absolute;
  left: 0;
  width: 6rem;
  height: 8rem;
  bottom: 4.5rem;
}
.rec4 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 7rem;
}

.slider {
  height: 4rem;
  padding: 1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    display: block;
    padding: 4rem 0;
    margin-top: 0 !important;
  }

  .flex1 {
    width: 100%;
  }
  .flex2 {
    width: 100%;
    margin-top: 2rem;
  }

  .tutor_msg {
    margin: auto;
    width: 80%;
    text-align: left;
    font-size: 0.9rem;
  }

  .flex1_title {
    font-size: 1.5rem;
    width: 80%;
    margin: auto;
    text-align: left;
  }

  .tutor_info {
    width: 80%;
    margin: 1rem auto;
  }

  .rec1 {
    width: 5rem;
    height: 7rem;
  }
  .rec2 {
    top: 4.5rem;
    width: 5rem;
  }
  .rec3 {
    width: 4rem;
    height: 5rem;
    bottom: 3.5rem;
  }
  .rec4 {
    width: 5rem;
  }
}
